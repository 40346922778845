import React from 'react';
import ContentPresenter from '../ContentPresenter';

class Page extends React.Component{
    render(){
        return (
            <ContentPresenter>
                {this.props.children}
            </ContentPresenter>
        )
    }
}