import React, { Component } from 'react';
import { Card, Row, Col } from 'antd';
import { Elements } from '.';
import moment from 'moment';
export interface DashboardElementContainerProps {
    header: React.ReactNode
    height?: number
    icon: React.ReactNode
    primaryColor: string
}

// class w extends Component<>

export class DashboardElementContainer extends Component<DashboardElementContainerProps, any>{
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="white-card round-border">
                <div className="dashboard-container-header-container">
                    <Row align="middle">
                        <Col className="dashboard-icon-container container-icon" style={{ background: this.props.primaryColor }}>
                            {this.props.icon}
                        </Col>
                        
                        <Col>
                            <Row className="dashboard-card-header container-title">
                                {this.props.header}
                            </Row>
                            <Row className="dashboard-card-header container-title-date">
                                {moment().format('YYYY MMMM DD')}
                            </Row>
                        </Col>
                        
                    </Row>
                </div>
                <div className="dashboard-container-content-container">{this.props.children}</div>
            </div>
        );
    }
}
