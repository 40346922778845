import React, { Component } from "react";
import {
	Upload,
	Button,
	Col,
	Row,
	Input,
	Card,
	message,
	Badge,
	Tag
} from "antd";
import prettyBites from "pretty-bytes";
import { RcFile } from "antd/lib/upload/interface";
import {
	MinusCircleFilled,
	FileOutlined,
	PaperClipOutlined,
	InboxOutlined
} from "@ant-design/icons";

const acceptsToAcceptString = (accepts: string[]): string =>
	accepts.reduce((pre: string, cur: string) => pre + "," + cur);
const acceptsToAcceptRegex = (accepts: string[]): string =>
	"(\\" +
	accepts.reduce((pre: string, cur: string) => pre + ")|(\\" + cur) +
	")";

export interface FileDataMapper {
	file: string;
	comment: string;
	url: string;
}

export interface UploaderState {
	data: Array<FileDataMapper>;
	files: Array<RcFile>;
}

export const defaultImageExtensions: Array<AcceptTypes> = [
	".png",
	".jpeg",
	".jpg",
	".PNG",
	".JPG",
	".JPEG",
	".doc",
	".docx",
	".docm",
	".dotm",
	".dotx",
	".pdf",
	".PDF"
];

type AcceptTypes =
	| string
	| ".png"
	| ".jpeg"
	| ".jpg"
	| ".PNG"
	| ".JPG"
	| ".JPEG"
	| ".doc"
	| ".docx"
	| ".docm"
	| ".dotm"
	| ".dotx"
	| ".pdf"
	| ".PDF";

interface UploaderProps {
	maxFileSize?: number;
	files?: Array<RcFile>;
	accept: Array<AcceptTypes>;
	multiple?: boolean;
	renderPreview: () => React.ReactElement;
	onFileRemove: (file: RcFile) => void;
	onFileUpload: (file: RcFile) => void;
	// onError?: (error: Error) => void
}

class Uploader extends Component<UploaderProps> {
	uploadProps: {};
	regex: RegExp;
	constructor(props: any) {
		super(props);
		this.regex = new RegExp(acceptsToAcceptRegex(this.props.accept));
		this.uploadProps = {
			onRemove: (file: RcFile) => {
				this.props.onFileRemove(file);
			},
			beforeUpload: (file: RcFile) => {
				if (file.name.match(this.regex)) {
					if (this.props.maxFileSize && file.size > this.props.maxFileSize)
						message.warning("Exceeds the maximum file-size");
					else this.props.onFileUpload(file);
				}
				return false;
			},
			accept: acceptsToAcceptString(this.props.accept),
			showUploadList: false,
			multiple: this.props.multiple
		};
	}

	render() {
		return (
			<Col flex='auto' span={24}>
				<Row>
					<Col flex='auto'>
						{this.props.maxFileSize && (
							<Tag style={{ position: "absolute", top: 5, left: 5, zIndex: 1 }}>
								Maximum File Size: <b>{prettyBites(this.props.maxFileSize)}</b>
							</Tag>
						)}
						<Upload.Dragger {...this.uploadProps}>
							{this.props.children}
						</Upload.Dragger>
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>{this.props.renderPreview()}</Row>
			</Col>
		);
	}
}

interface SingleFileUploaderProps {
	accept: Array<AcceptTypes>;
	text?: string;
	onChange: (file: RcFile | undefined) => void;
}

interface SingleFileUploaderState {
	file?: RcFile;
}

export class SingleFileUploader extends Component<
	SingleFileUploaderProps,
	SingleFileUploaderState
> {
	constructor(props: any) {
		super(props);
		this.onUpload = this.onUpload.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.renderFilePreview = this.renderFilePreview.bind(this);
		this.state = {
			file: undefined
		};
	}

	onRemove(file: RcFile) {
		this.setState(
			(prevState: SingleFileUploaderState) => {
				prevState.file = undefined;
				return prevState;
			},
			() => this.props.onChange(this.state.file)
		);
	}

	onUpload(file: RcFile) {
		this.setState(
			(prevState: SingleFileUploaderState) => {
				prevState.file = file;
				return prevState;
			},
			() => this.props.onChange(this.state.file)
		);
	}

	renderFilePreview() {
		if (this.state.file)
			return (
				<Col flex='auto'>
					<FilePreview file={this.state.file} onRemove={this.onRemove} />
				</Col>
			);
		return <Col></Col>;
	}

	render() {
		return (
			<Uploader
				maxFileSize={3000000}
				accept={this.props.accept}
				onFileRemove={this.onRemove}
				onFileUpload={this.onUpload}
				renderPreview={this.renderFilePreview}
				multiple={false}>
				<p className='ant-upload-drag-icon'>
					<InboxOutlined />
				</p>
				<p className='ant-upload-text'>
					{this.props.text
						? this.props.text
						: "Click or drag file to this area to upload"}
				</p>
				<p className='ant-upload-hint'>
					Support for a single or bulk upload. Strictly prohibit from uploading
					company data or other band files
				</p>
			</Uploader>
		);
	}
}

interface MultipleFileUploaderState extends UploaderState {
	files: Array<RcFile>;
}

interface MultipleFileUploaderProps {
	justPreview?: boolean;
	accept: Array<AcceptTypes>;
	onChange: (files: UploaderState) => void;
	text?: string;
	heading?: string;
}

export class MultipleFileUploader extends Component<
	MultipleFileUploaderProps,
	MultipleFileUploaderState
> {
	constructor(props: any) {
		super(props);
		this.onUpload = this.onUpload.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onPreviewChange = this.onPreviewChange.bind(this);
		this.renderFilePreview = this.renderFilePreview.bind(this);
		this.state = {
			files: [],
			data: []
		};
	}

	onRemove(file: RcFile) {
		this.setState(
			(prevState: MultipleFileUploaderState) => ({
				files: prevState.files.filter((data: RcFile) => file.name !== data.name),
				data: prevState.data.filter(
					(data: FileDataMapper) => file.name !== data.file
				)
			}),
			() => this.props.onChange(this.state)
		);
	}

	onUpload(file: RcFile) {
		if (
			this.state.files.filter((_file: RcFile) => _file.name == file.name).length ==
			0
		)
			this.setState(
				(prevState: MultipleFileUploaderState) => {
					prevState.files.push(file);
					prevState.data.push({
						file: file.name,
						comment: "",
						url: ""
					});
					return prevState;
				},
				() => this.props.onChange(this.state)
			);
	}

	onPreviewChange(data: FileDataMapper) {
		this.setState(
			(prevState: MultipleFileUploaderState) => ({
				data: prevState.data.map((file: FileDataMapper) =>
					data.file === file.file ? data : file
				)
			}),
			() => this.props.onChange(this.state)
		);
	}

	renderFilePreview() {
		if (this.state.files)
			return (
				<Col flex='auto'>
					{this.state.files.map((file: RcFile) => (
						<MultipleFilePreview
							justPreview={this.props.justPreview}
							file={file}
							onChange={this.onPreviewChange}
							onRemove={this.onRemove}
						/>
					))}
				</Col>
			);
		return <Col></Col>;
	}

	render() {
		return (
			<Uploader
				maxFileSize={3000000}
				accept={this.props.accept}
				onFileRemove={this.onRemove}
				onFileUpload={this.onUpload}
				renderPreview={this.renderFilePreview}
				multiple={true}>
				<p className='ant-upload-drag-icon'>
					<PaperClipOutlined />
				</p>
				<p className='ant-upload-text'>
					{this.props.heading
						? this.props.heading
						: "Click or drag file to this area to upload"}
				</p>
				<p className='ant-upload-hint'>
					{this.props.text
						? this.props.text
						: " Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files"}
				</p>
			</Uploader>
		);
	}
}

interface FilePreviewProps {
	file: RcFile;
	onRemove: (file: RcFile) => void;
}

class FilePreview extends Component<FilePreviewProps, any> {
	render() {
		return (
			<Card>
				<Row align='middle' gutter={10}>
					<Col>
						<FileOutlined style={{ fontSize: 40 }} />
					</Col>
					<Col flex='auto'>
						<Col style={{ marginBottom: 10 }}>
							<Row className='file-upload-file-name'>{this.props.file.name}</Row>
							<Row className='file-upload-file-size'>
								{prettyBites(this.props.file.size)}
							</Row>
						</Col>
					</Col>
					<Col>
						<Button type='link' onClick={() => this.props.onRemove(this.props.file)}>
							<MinusCircleFilled />
						</Button>
					</Col>
				</Row>
			</Card>
		);
	}
}

interface MultipleFilePreviewProps {
	justPreview?: boolean;
	file: RcFile;
	onRemove: (file: RcFile) => void;
	onChange: (data: FileDataMapper) => void;
}

class MultipleFilePreview extends Component<MultipleFilePreviewProps, any> {
	constructor(props: any) {
		super(props);
		this.onComment = this.onComment.bind(this);
		this.onUrl = this.onUrl.bind(this);
		this.state = {
			comment: "",
			url: ""
		};
	}

	onComment(text: string) {
		this.setState({
			comment: text
		});
		this.props.onChange({
			file: this.props.file.name,
			comment: text,
			url: this.state.url
		});
	}

	onUrl(text: string) {
		this.setState({
			url: text
		});
		this.props.onChange({
			file: this.props.file.name,
			comment: this.state.comment,
			url: text
		});
	}
	render() {
		return (
			<Card style={{ marginBottom: 10 }}>
				<Row align='middle' gutter={10}>
					<Col>
						<FileOutlined style={{ fontSize: 40 }} />
					</Col>
					<Col flex='auto'>
						<Col style={{ marginBottom: 10 }}>
							<Row className='file-upload-file-name'>{this.props.file.name}</Row>
							<Row className='file-upload-file-size'>
								{prettyBites(this.props.file.size)}
							</Row>
							{!this.props.justPreview && (
								<React.Fragment>
									<Row style={{ padding: 10, paddingLeft: 0 }}>
										<Input.TextArea
											rows={1}
											placeholder='Comment'
											onChange={({ target: { value } }) => this.onComment(value)}
										/>
									</Row>
									<Row style={{ padding: 10, paddingLeft: 0 }}>
										<Input
											placeholder='File link'
											onChange={({ target: { value } }) => this.onUrl(value)}
										/>
									</Row>
								</React.Fragment>
							)}
						</Col>
					</Col>
					<Col>
						<Button type='link' onClick={() => this.props.onRemove(this.props.file)}>
							<MinusCircleFilled />
						</Button>
					</Col>
				</Row>
			</Card>
		);
	}
}
