import React,{Component} from 'react';

interface SimpleHeaderProps{
    title: string
}

export class SimpleHeader extends Component<SimpleHeaderProps, any>{
    render(){
        return(
            // <div className="simple-header-container">{this.props.title}</div>
           this.props.title
        )
    }
}