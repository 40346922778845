import React, { Component } from "react";
import { Menu, Row, Col, Popover, Button, Badge, message, PageHeader, Divider, Switch as Toggler, Avatar, Input } from 'antd';
import { ShowTheLocationWithRouter, ContentPresenter, DefaultAvatar, NotificationArtworkCard, SimpleHeader, PoweredByFooter } from './components'
import { CreateArtWorkPage, Dashboard, ViewArtWork, ViewAllArtWorks , Help , ViewImage , UploadDocuments} from './pages'
import { logOutAsLionUser, lionUserManager, userManager } from './repos/UserRepo'
import { ArtWorkActions } from './repos/ArtWorksRepo'
import DefaultLayout from './layouts/DefaultLayout';
import { connect } from 'react-redux'
import {
    withRouter,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import './App.css';
import { RouteConfig } from "./route.config";
import {
    AppstoreOutlined,
    TeamOutlined,
    FileImageOutlined,
    PictureOutlined,
    FileTextOutlined,
    BellFilled,
    ReconciliationOutlined
} from '@ant-design/icons';
import './ProtectedApp.css';
import logo from '../img/logo.png';
import Artwork from "./repos/models/Artwork";
import { LionUser } from "user-manager";

class ProtectedApp extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { collapsed: true, };
    }

    toggle() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <DefaultLayout
                header={<ProtectedAppHeaderWithUser />}
                sidebar={<ProtectedAppSidebar collapsed={this.state.collapsed} toggle={this.toggle.bind(this)} />}
                isSidebarCollapsed={this.state.collapsed}>
                {/* <FilterHeaderStrip /> */}
                <div className="breadcrumbs-container">
                    <ShowTheLocationWithRouter />
                </div>
                <Switch>
                    <Route exact path={RouteConfig.PROTECTED_MAIN_DASHBOARD}>
                        <Dashboard />
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_CREATE_ARTWORK}>
                        <ContentPresenter><CreateArtWorkPage /></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_VIEW_ARTWORK}>
                        <ContentPresenter><ViewArtWork /></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_VIEW_ALL_ARTWORKS}>
                        <ContentPresenter><ViewAllArtWorks /></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_VIEW_HELP}>
                        <ContentPresenter><Help /></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.VIEW_IMAGE}>
                        <ContentPresenter><ViewImage /></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_UPLOAD_DOCS}>
                        <ContentPresenter><UploadDocuments /></ContentPresenter>
                    </Route>
                    <Route path={RouteConfig.PROTECTED_ROOT}>
                        <Redirect to={{ pathname: RouteConfig.PROTECTED_MAIN_DASHBOARD }} />
                    </Route>
                </Switch>
            </DefaultLayout>
        );
    }
}

class FilterHeaderStrip extends Component<any> {
    constructor(props: any) {
        super(props);
    }

    render(){
        return(
            <Col className="header-strip-container">
                <Row>
                    <Input />
                </Row>
            </Col>
        )
    }
}

class ProtectedAppHeader extends Component<any> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this._getNotification(); 
    }

    async _getNotification() {
        try {
            await this.props.getNotificationList()
            console.log("notificationData " , this.props.ArtworkRepo.notificationData)
        } catch (err) {
            message.error(err.message);
        }
    }

    render() {
        const data = this.props.ArtworkRepo.notificationData && this.props.ArtworkRepo.notificationData
        return (
            <Row justify="end" align="middle" className="main-header-container" gutter={35}>
                <Col className="main-header-notification-icon">
                    <Popover content={data && <NotificationContainer data={data} />} placement="topLeft" trigger="click">
                        <Badge count={data && data.length}>
                            <div className="header-icon"><BellFilled /></div>
                        </Badge>
                    </Popover>
                </Col>
                <Col>
                    <Popover content={userManager.user && <UserPopover user={userManager.user} />} placement="topLeft" trigger="click">
                        <div><DefaultAvatar user={lionUserManager.user} /></div>
                    </Popover>
                </Col>
            </Row>

        );
    }
}

export class UserPopover extends Component<{ user: LionUser }>{
    render() {
        return (
            <Row className="user-popover-container" gutter={[10, 10]} align="middle" justify="center">
              {lionUserManager &&  <Col>
                    <Row align="middle" justify="center" className="user-popover-avatar-name">  
                         <Avatar src={lionUserManager.user.data.User.imagePaths} size={70} />
                    </Row>
                    <Row style={{marginTop:6}} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                          {lionUserManager.user.data.User.firstName + " " + lionUserManager.user.data.User.lastName}
                    </Row>
                    <Row style={{marginTop:6}} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                          {lionUserManager.user.data.User.designationName}
                    </Row>
                    <Row style={{marginTop:1}} align="middle" justify="center" className="user-popover-avatar-email" gutter={[10, 10]}>
                          {lionUserManager.user.data.User.email}
                    </Row>
                    <Row style={{marginTop:6}} align="middle" justify="center">
                        <Button onClick={logOutAsLionUser} shape="round" className="user-popover-user-sign-out-button">Sign Out</Button>
                    </Row>
              </Col> }
            </Row>
        );
    }
}

class NotificationContainer extends Component<{ data: [Artwork] }>{
    render() {
        return (
            <Row style={{ width: 400 }}>
                <Col span={24}>
                    <SimpleHeader title="Notifications" />
                </Col>
                <Col className="notification-container">{
                    this.props.data.map((artwork: Artwork) => (
                        <Link to={{ pathname: `/protected/view/${artwork.id}` }}>
                            <NotificationArtworkCard artwork={artwork} key={artwork.id} status="" />
                        </Link>
                    ))
                }</Col>
            </Row>
        );
    }
}

class ProtectedAppSidebar extends Component<{ collapsed: boolean, toggle: () => void } | any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="sidebar">
                    <div className="flex-center logo"  >
                        <img src={logo} style={{ width: '100%' }}></img>
                    </div>

                    <Menu mode="inline" defaultSelectedKeys={['0']}>
                        <Menu.Item key="0">
                            <Link to="/protected/dashboard">
                                <AppstoreOutlined />
                                <span>Dashboard</span>
                            </Link>
                        </Menu.Item>

                        {/* <Menu.Item key="1">
                            <Link to="/protected/dashboard">
                                <TeamOutlined />
                                <span>User Management</span>
                            </Link>
                        </Menu.Item> */}
                        {lionUserManager.user.data.AUTHORITIES.includes("initiator") &&
                            <Menu.Item key="2">
                                <Link to="/protected/artwork-create">
                                    <FileImageOutlined />
                                    <span>Initiate Artwork</span>
                                </Link>
                        </Menu.Item> }

                        <Menu.Item key="3">
                            <Link to="/protected/view-all-artworks">
                                <PictureOutlined />
                                <span>All Artworks</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="5">
                            <Link to="/protected/help-and-support">
                                <FileTextOutlined />
                                <span>Help & Support</span>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </div>

                <span className="sidebar-toggle-button">
                    <Toggler size="small" defaultChecked={this.props.collapsed} onChange={this.props.toggle} />
                </span>
            </React.Fragment>
        )
    }
}

const ProtectedAppHeaderWithUser = connect((state => state), ArtWorkActions)(ProtectedAppHeader);

export default withRouter(ProtectedApp);