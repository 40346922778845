import React from 'react';
import { Form, Row, Col } from 'antd';
import { DashboardActions } from '../repos/DashboardRepo';
import { connect } from 'react-redux'
import { PoweredByFooter } from '../components'
import { Elements } from '../components/Dashboard'

import {
  CoffeeOutlined,
  CommentOutlined,
  PaperClipOutlined,
  CloudOutlined,
  NumberOutlined,
  FileTextOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import Title from 'antd/lib/skeleton/Title';

export const DashboardMediumSizedElement = {
  xl: 12,
  xs: 24,
  sm: 24,
  lg: 12,
}

export const smallCardMediaSpans = {
  xl: 6,
  xs: 24,
  sm: 24,
  lg: 12,
}

const mock = [
  {
    "title": "Artworks",
    "value": 7
  },
  {
    "title": "Completed",
    "value": 4
  },
  {
    "title": "Rejected",
    "value": 2
  },
  {
    "title": "Pending",
    "value": 1
  },
  {
    "title": "My Pending",
    "value": 0
  }
]

class Dashboard extends React.Component<any, any>  {
  state = {
    expand: false,
    loading: false
  };

  componentDidMount() {
    this._getData()
  }

  async  _getData() {
   
    this.props.getArtworkDashboardSummary();
    this.props.getData();
  }

  render() {

    const { summaryData, tagCardData } = this.props.DashboardRepo;
    let sortedSummaryData: any[] = []; 

    if (summaryData && summaryData.length > 0) {
      const rawSummaryData = summaryData.map((data: { title: string, value: any }, key: string) => ({ action: data.title, pv: data.value })).filter(({pv}: any) => pv && pv > 0)
      sortedSummaryData = _.sortBy(rawSummaryData, 'pv').reverse()
    }
 
    return (
      <React.Fragment>
        <Row gutter={[10, 20]}>
          {/* <Col {...DashboardMediumSizedElement}>
            <Row gutter={[10, 10]}>
              
            </Row>
          </Col> */}
          {/* {
            tagCardData && Object.keys(tagCardData).map((key: string, i: number) => <Col {...smallCardMediaSpans}>
              <Elements.ValueCard
                header={key}
                backgroundColor={getColor(i)}
                icon={<NumberOutlined />}>
                {this.props.DashboardRepo.tagCardData[key]}
              </Elements.ValueCard>
            </Col>)
          } */}
          <Col span={24}>
            <Row gutter={[10, 10]}>
              {
                (tagCardData && tagCardData.length > 0) && tagCardData.map((key: any, i: number) => <Col {...smallCardMediaSpans}>
                  <Elements.ValueCard
                    header={key.title}
                    backgroundColor={getColor(i)}
                    icon={<FileTextOutlined />}>
                    {key.value}
                  </Elements.ValueCard>
                </Col>)
              }
            </Row>
          </Col>
          {(sortedSummaryData && sortedSummaryData.length > 0) && <Col {...DashboardMediumSizedElement}>
            <Elements.SummeryCard
              value={sortedSummaryData}
              backgroundColor="#F64B53"
              icon={<PaperClipOutlined />} />
          </Col>}
          {/* <Col {...DashboardMediumSizedElement}>
            <Elements.GraphCard
              value={9}
              backgroundColor="#F64B53"
              icon={<PaperClipOutlined />} />

          </Col>
          <Col {...DashboardMediumSizedElement}>
            <Elements.GraphCard
              value={9}
              backgroundColor="#29275F"
              icon={<PaperClipOutlined />} />

          </Col> */}
        </Row>
        {/* <PoweredByFooter /> */}
      </React.Fragment>
    );
  }
}

const getColor = (i: number) => {
  switch (i) {
    case 0: return '#4C56BA'
    case 1: return '#29275F'
    case 2: return '#F64B53'
    case 3: return '#929CB7'
    default: return '#0B3C49'
  }
}

// Object.keys(this.props.DashboardRepo.summaryData).map((key: string) => ({
//   action: 'key',
//   pv: 10
// }))

const WrappedDashboardView = Dashboard;
const withDashboardRepo = connect(((state: any) => state), DashboardActions)(WrappedDashboardView);

export default withDashboardRepo;

{/* <Col {...DefaultMediaSpans}>
            <Elements.ValueCard
              value={9}
              backgroundColor="#4C56BA"
              icon={<CoffeeOutlined />}>
              289
          </Elements.ValueCard>
          </Col>
          <Col {...DefaultMediaSpans}>
            <Elements.ValueCard
              value={9}
              backgroundColor="#29275F"
              icon={<CommentOutlined />}>
              120
          </Elements.ValueCard>
          </Col>
          <Col {...DefaultMediaSpans}>
            <Elements.ValueCard
              value={9}
              backgroundColor="#F64B53"
              icon={<PaperClipOutlined />}>
              25
          </Elements.ValueCard>
          </Col>
          <Col {...DefaultMediaSpans}>
            <Elements.ValueCard
              value={9}
              backgroundColor="#929CB7"
              icon={<CloudOutlined />}>
              865
          </Elements.ValueCard> */}