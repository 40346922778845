import React from 'react';
import { Typography, Row, Card, Col, Tooltip } from 'antd';

import { imgBaseUrl } from '../api.config'
const { Text } = Typography;
const moment = require('moment');
const { Meta } = Card;

interface ArtworkCardProps {
    name: string,
    code: string,
    img: string
    subImages: any
}

export default class PrintComponent extends React.Component<ArtworkCardProps> {
    render() {
        return (
            <div style={{ marginTop: 20 }} >
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                    <Text strong style={{ fontSize: 21 }}>Code :  {this.props.code}  </Text>
                </div> 

                <div style={{ marginLeft: 80, marginTop: this.props.subImages.length == 1 ? 100 : 20 }}>
                    <Row style={{ marginTop: 10 }} >

                        {this.props.subImages && this.props.subImages.map((image: any) =>

                            <Col span={this.props.subImages.length == 1 ? 22 : 10}  >

                                <Card
                                    className="artwork-card-container"
                                    style={{ margin: 10 }}
                                    bodyStyle={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px" }}
                                    hoverable
                                    cover={

                                        <div style={{  backgroundImage: `url('${imgBaseUrl}${image.thumbnail}')` }} className= "single-print-image"></div>

                                    }>

                                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: 21 }}>  {image.comment}  </Text>

                                    </div>
                                </Card>

                            </Col>



                        )}
                    </Row>
                </div>

                {/* <Col style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} span={11}> <Text style={{ margin : 13 , fontSize: 20 }}>Created : 12-03-2020 12:00:01</Text></Col> */}
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 30 }}  ><Text style={{ fontSize: 20 }}>Printed  : {moment().format('DD-MM-YYYY, hh:mm:ss A')}</Text> </div>


            </div>
        );
    }
}