import React from "react";
import { Button, Row, Col, Card, Typography, PageHeader, Tag, Badge } from "antd";
import { texts } from "../texts.config";
import Artwork from "../repos/models/Artwork";
import MinorVersions from "../repos/models/MinorVersions";
import { imgBaseUrl } from "../api.config";
import "./ArtworkComponent.css";
const { Meta } = Card;
const { Title } = Typography;

export default class ArtworkComponent extends React.Component<Artwork, {}> {

	renderArtworkStatusBadge = () => {
		console.log({artwork: this.props})
		if (this.props.isMainVersionLock) {
			return <Tag color="#52c41a" >COMPLETED</Tag>;
		}

		if (this.props.isReject && !this.props.isActive) {
			return <Tag color="#f5222d" >REJECTED COMPLETELY</Tag>;
		}

		if (this.props.isReject) {
			return <Tag color="#f5222d" >REJECTED</Tag>;
		}

		return <Tag color="#ffc440" >PENDING</Tag>;
	};

	render() {
		return (
			<Card
				className='artwork-card-container'
				style={{ height: "100%" }}
				bodyStyle={{
					paddingLeft: "10px",
					paddingRight: "10px",
					paddingTop: "5px",
					paddingBottom: "5px",
				}}
				hoverable
				cover={
					// <img alt="example" src={imgBaseUrl + this.props.activeImage} style={{ maxHeight: 500 }} />

					<div
						style={{
							backgroundImage: `url('${imgBaseUrl}${this.props.activeImage}')`,
						}}
						className='small-artwork-image-container'></div>
					// <div style={{backgroundImage: `https://lionapi.eimsky.com/artwork/downloadFile/Lion%20Brewery%20-%20Local-Ryder's%20Wild%20Apple-Cans-330ml-Exist%20v1.1.png`}} className="small-artwork-image-container"></div>
				}>
				<Row>
					<Col flex='auto'>
						{/* {this.props.isMainVersionLock ? (
							<Tag color='#2feb3d'>Completed</Tag>
						) : !this.props.isMainVersionLock && this.props.isReject ? (
							<Tag color='#f91823'>Rejected</Tag>
						) : (
							<Tag color='#ebd42f'>Pending</Tag>
						)} */}
						{this.renderArtworkStatusBadge()}
					</Col>
					<Col><Tag><b>{this.props.id}</b></Tag></Col>
				</Row>

				<div className='artwork-card-title'>
					{/* <Row>
            <Col className="artwork-card-main">{this.props.status}</Col>
          </Row> */}
					<Row>
						<Col className='artwork-card-main'>{this.props.artworkVersion}</Col>
					</Row>
					<Row>
						<Col className='artwork-card-date'>
							{this.props.create_on.replace("T", " ").split(".")[0]}
						</Col>
					</Row>
					<Col className='artwork-card-description'>
						<p>
							{this.props.description ? (
								this.props.description
							) : (
								<i>"Description is not provided by the creator"</i>
							)}
						</p>
					</Col>

					{this.props.user != null && (
						<PageHeader
							style={{ width: "100%", padding: 4 }}
							title=''
							subTitle={
								this.props.user.userDetails.firstName +
								" " +
								this.props.user.userDetails.lastName
							}
							avatar={{ src: this.props.user.userDetails.imagePaths }}></PageHeader>
					)}
				</div>
			</Card>
		);
	}
}
