import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = { tagCardData: null, summaryData: null }, action: { type: DashboardActionTypes, payload: any }) => {
    switch (action.type) {
        case DashboardActionTypes.GET_ALL_TAG_CARD_DATA: {
            return { ...state, tagCardData: action.payload }
        }
        case DashboardActionTypes.ARTWORK_DASHBOARD_SUMMARY: {
            return { ...state, summaryData: action.payload }
        }
        default: return state;
    }
}

export const DashboardActions = (dispatch: any) => ({
    getData: async () => {
        try {
            const res = await lionUserManager.authenticationManager.get('/artwork/dashboard/get/' + lionUserManager.user.data.USER_ID);
            console.log("res " , res)
            if (res.status == 200) {
                dispatch({
                    type: DashboardActionTypes.GET_ALL_TAG_CARD_DATA,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },
    getArtworkDashboardSummary: async () => {
        try { 
            const res = await lionUserManager.authenticationManager.get('/artwork/dashboard/getSummary');
            console.log({res})
            if (res.status == 200) {  
                    dispatch({
                        type: DashboardActionTypes.ARTWORK_DASHBOARD_SUMMARY,
                        payload: res.data
                    });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }

    },
})

export class DashboardActionTypes {
    static GET_ALL_TAG_CARD_DATA = 'GET_ALL_TAG_CARD_DATA';
    static ARTWORK_DASHBOARD_SUMMARY = 'ARTWORK_DASHBOARD_SUMMARY'
}