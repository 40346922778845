import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux'
import { GlobalFilterActions, Filters, IFilter } from '../repos/GlobalFilterRepo'
import { lionUserManager } from '../repos/UserRepo'
import { message, Form, Upload, Button, Input, Typography, Row, Col, Card, Breadcrumb } from 'antd';
import { AxiosResponse } from 'axios';
import { texts } from '../texts.config';
import { withRouter } from 'react-router';
import { FolderOutlined, SubnodeOutlined, FolderOpenOutlined, LoadingOutlined, CheckOutlined, ExclamationOutlined } from '@ant-design/icons'
import { UploaderState, FileDataMapper, SingleFileUploader, MultipleFileUploader, defaultImageExtensions } from '../components';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { CategoryFilter } from '../components'
import { INode } from '../components'

const { Title, Paragraph, Text } = Typography;
interface CreateArtWorkPageState {
    file: RcFile | undefined,
    filterData: [],
    description: string,
    reason: string,
    fileMapperData: UploaderState
    validation: {
        filters: boolean,
        reason: boolean,
        comment: boolean,
        file: boolean
    }
}

class CreateArtWorkPage extends React.Component<any, CreateArtWorkPageState>{
    constructor(props: any) {
        super(props);
        this.onMultipleUploaderSubmit = this.onMultipleUploaderSubmit.bind(this) 
        this.state = {
            file: undefined,
            filterData: [],
            description: "",
            reason: "",
            fileMapperData: {
                files: [],
                data: []
            },
            validation: {
                filters: false,
                reason: true,
                comment: true,
                file: false
            }
        };
    }

    async syncFilters() {
        await this.props.syncFilters();
    }
 

    async _createArtwork() {
        var hide;
        try {

            hide = message.loading('Action in progress..', 0);

            const activeProcessedFilterCollection = this.props.GlobalFilterRepo.map((filter: IFilter<Array<INode<any>>>) => this._convert(filter.active))
            const activeProcessedFlatFilterCollection = _.flatten(activeProcessedFilterCollection)

            if (true) {

            var form = new FormData();

            

            this.state.fileMapperData.files.forEach((element: any) => {
                form.append("imageList", element);
            });

            form.append("imageDetails", JSON.stringify(this.state.fileMapperData.data.map((data: FileDataMapper) => ({
                thumbnail: data.file,
                comment: data.comment,
                imageUrl: data.url
            }))));


            console.log(this.state.fileMapperData.data.map((data: FileDataMapper) => ({
                thumbnail: data.file,
                comment: data.comment,
                imageUrl: data.url
            })))


            return;
            

            const res: AxiosResponse = await lionUserManager.authenticationManager.post(
                '/artwork/mainVersion/create',
                form
            )

            if (res.status == 200) {
                console.log("Create Success Log" , res)
                message.success(texts.ARTWORK_CREATION_SUCCESS);
                const { match, location, history } = this.props;
              //  history.push(`view/${res.data.id}`)

            } else {
                console.log("Create Error Log" , res)
                throw new Error(texts.ARTWORK_CREATION_ERROR)
            }
            } else {
                message.warn("Please check inputs")
            }
        } catch (err) {
            message.error(err.message);
        } finally {
            hide && hide()
        }
    }

    _convert(e: any[]) {
        return e.filter(({ data }) => {
            const { type, masterData } = data;
            if (data != null)
                if (type.id != undefined && masterData.id != undefined)
                    return true

        }).map(({ data }) => {
            const { type, masterData, id } = data;
            return ({
                category: {
                    id: type.id
                },
                categoryMasterData: {
                    id: id
                }
            })
        })
    }

    _onHistoryChange(e: any[], index: number) {
        const converted = this._convert(e);
        // this.state.filterData[index] = converted;
        this.setState({
            filterData: this.state.filterData
        })
        this.props.setFilter(_.flatMap(this.state.filterData))
    }

    onMultipleUploaderSubmit(data: UploaderState) {
        this.setState({
            fileMapperData: data
        })
    }

  
    render() {
        const props = {

            onRemove: (file: any) => {
                this.setState({ file });
            },
            beforeUpload: (file: any) => {

                if (this.state.file == undefined) {
                    this.setState({ file });

                }
                else {
                    message.error("You Can Not Upload Multyple Files..");
                }

                return false;
            },
            accept: ".jpeg,.png",
            multiple: false,

        }
        // const mainCats = normalizeMainCat(this.props.GlobalFilterRepo.filters).map(normalize);
        return (
            <Form className="ant-advanced-search-form">
                {/* {
                    mainCats && mainCats.map((e: any, i: number) =>
                        <Form.Item key={i}>
                            <CategoryFilter key={i} data={e} onHistoryChange={(_e: any) => this._onHistoryChange(_e, i)} />
                        </Form.Item>
                    )
                } */}

              
                <Form.Item>
                    <div>
                        <MultipleFileUploader accept={defaultImageExtensions} onChange={this.onMultipleUploaderSubmit} />
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" shape="round" htmlType="submit" onClick={this._createArtwork.bind(this)}>Upload File</Button>
                </Form.Item>
            </Form>
        );
    }
}


// const withFilter = connect((state => state), UserRepoActions)(_CreateArtWorkPage);
export default withRouter(connect((state => state), GlobalFilterActions)(CreateArtWorkPage) as React.FC)
