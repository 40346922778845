import React from "react";
import { connect } from "react-redux";
import { ArtWorkActions } from "../repos/ArtWorksRepo";
import { Pagination, Row, Col, message, Button, DatePicker } from "antd";
import Artwork from "../repos/models/Artwork";
import {
	SmallArtworkCard,
	SmallArtworkCardWithoutImage,
	SmallArtworkCardList,
} from "../components/Cards";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
	FilterOutlined,
	AppstoreOutlined,
	FileImageOutlined,
	OrderedListOutlined,
} from "@ant-design/icons";
import Filter, {
	INode,
	getChildren,
	getHistory,
} from "../components/Filters/ColumnGallery";
import { CategoryFilter } from "../components";
import { GlobalFilterActions, IFilter } from "../repos/GlobalFilterRepo";
import Search from "antd/lib/input/Search";
const { RangePicker } = DatePicker;
class ViewAllArtworks extends React.Component<any, any> {
	defaultFilter = (_: any) => true;
	constructor(props: any) {
		super(props);
		this.toggleFilterPanel = this.toggleFilterPanel.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this._convert = this._convert.bind(this);
		this.searchByArtworkId = this.searchByArtworkId.bind(this);
		this._getAll = this._getAll.bind(this);
		this.onFilterDateRangeChange = this.onFilterDateRangeChange.bind(this);
		this.state = {
			current: 1,
			filter: this.defaultFilter,
			isFilterPanelHidden: false,
			withImageView: 0,
			filterDateRangeValue: null,
			listView: false,
			isSearchBusy: false
		};
	}

	componentDidMount() {
		this._getAll(1);
	}

	onChange = (page: any) => {
		this.setState({
			current: page,
		});
		this._getAll(page);
	};

	async _getAll(page: any) {
		var hide;
		try {
			hide = message.loading("Action in progress..", 0);
			await this.props.getArtWorksCount();
			await this.props.getArtWorksByPage(page);
			console.log("sasasa ", this.props.ArtworkRepo.allArtWorks);
			// await this.props.getAllArtWorks();
		} catch (err) {
			message.error(err.message);
		} finally {
			hide && hide();
		}
	}

	toggleFilterPanel() {
		this.setState({
			isFilterPanelHidden: !this.state.isFilterPanelHidden,
		});
	}

	onFilterDateRangeChange = (value: any, dateString: any) => {
		this.setState({
			filterDateRangeValue: value,
		});
	};

	async onFilter(page: any) {
		const activeProcessedFilterCollection = this.props.GlobalFilterRepo.map(
			(filter: IFilter<Array<INode<any>>>) => this._convert(filter.active)
		);
		const activeProcessedFlatFilterCollection = _.flatten(
			activeProcessedFilterCollection
		);

		var hide;
		try {
			if (this.state.filterDateRangeValue != null) {
				const [startDate, endDate] = this.state.filterDateRangeValue;
				await this.props.getFilteredArtworks(
					activeProcessedFlatFilterCollection,
					this.state.current,
					startDate,
					endDate
				);
			} else {
				await this.props.getFilteredArtworks(
					activeProcessedFlatFilterCollection,
					this.state.current,
					null,
					null
				);
			}

			hide = message.loading("Action in progress..", 0);
		} catch (err) {
			message.error(err.message);
		} finally {
			hide && hide();
			this.toggleFilterPanel();
		}
	}

	viewChange = (status: any) => {
		this.setState({ withImageView: status });
	};

	_convert(e: any[]) {
		return e
			.filter(({ data }) => {
				const { type, masterData } = data;
				if (data != null)
					if (type.id != undefined && masterData.id != undefined) return true;
			})
			.map(({ data }) => {
				const { type, masterData, id } = data;
				return {
					category: {
						id: type.id,
					},
					categoryMasterData: {
						id: id,
					},
				};
			});
	}

	onChange1 = (value: any, dateString: any) => {
		console.log("From: ", value[0], ", to: ", value[1]);
		console.log("From: ", dateString[0], ", to: ", dateString[1]);
	};

	async searchByArtworkId(id: string) {
		this.setState({
			isSearchBusy: true
		})
		await this.props.getArtwrorksById(id, this.state.current)
		this.setState({
			isSearchBusy: false
		})
		return;
	}

	render() {
		const artworks = (this.props.ArtworkRepo.allArtWorks ?? []).filter(
			this.state.filter
		);

		return (
			<div>
				<Row justify='space-between' align='middle'>
					<Col>
						<Pagination
							current={this.state.current}
							onChange={this.onChange}
							total={this.props.ArtworkRepo.artworkCount + 2}
						/>
					</Col>
					{/* <Col>
                    <Button onClick={this.toggleFilterPanel}>
                        <FilterOutlined />
                    </Button>
                </Col> */}
				</Row>
				{/* {
                this.state.isFilterPanelHidden && <React.Fragment>

                    <Row justify="end" gutter={10} style={{ marginTop: 10 }}>
                        <Col>
                            <Button onClick={this.onFilter}>Filter</Button>
                        </Col>
                        <Col>
                            <Button onClick={() => { this._getAll(1); this.toggleFilterPanel() }} type="danger">Clear</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col flex="auto">
                            <FilterWithGlobalFilters />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }}>

                           <RangePicker style={{ marginLeft: 15 }} onChange={this.onFilterDateRangeChange} />

                    </Row>
                </React.Fragment>

            } */}
				<Row style={{ marginTop: 20 }} align='middle' gutter={10}>
					<Col>
						<Search
							allowClear
							loading={this.state.isSearchBusy}
							placeholder='Search by ID'
							onChange={async (event) => {
								if (event.target.value === "") {
									this.searchByArtworkId(event.target.value);
								}
							}}
							onSearch={async (value) => {
								this.searchByArtworkId(value);
							}}
							enterButton
						/>
					</Col>
					<Col>
						<Button
							type='primary'
							shape='circle'
							icon={<AppstoreOutlined />}
							onClick={() => this.viewChange(0)}
						/>
					</Col>
					<Col>
						<Button
							type='primary'
							shape='circle'
							icon={<OrderedListOutlined />}
							onClick={() => this.viewChange(2)}
						/>
					</Col>
					<Col>
						<Button
							type='primary'
							shape='circle'
							icon={<FileImageOutlined />}
							onClick={() => this.viewChange(1)}
						/>
					</Col>
				</Row>

				{this.state.withImageView == 0 && (
					<Row style={{ marginTop: 15 }} gutter={[10, 10]}>
						{artworks.map((artwork: Artwork) => (
							<Col xl={6} xs={24} sm={24} lg={12} key={artwork.id}>
								<Link to={`view/${artwork.id}`}>
									<SmallArtworkCardWithoutImage
										artwork={artwork}
										key={artwork.id}
										status='aa'
									/>
								</Link>
							</Col>
						))}
					</Row>
				)}
				{this.state.withImageView == 1 && (
					<Row style={{ marginTop: 15 }} gutter={[10, 10]}>
						{artworks.map((artwork: Artwork) => (
							<Col xl={6} xs={24} sm={24} lg={12} key={artwork.id}>
								<Link to={`view/${artwork.id}`}>
									<SmallArtworkCard artwork={artwork} key={artwork.id} status='aa' />
								</Link>
							</Col>
						))}
					</Row>
				)}
				{this.state.withImageView == 2 && (
					<div>
						<Row>
							{artworks.map((artwork: Artwork) => (
								<Col xl={24} xs={24} sm={24} lg={24} key={artwork.id}>
									<Link to={`view/${artwork.id}`}>
										<SmallArtworkCardList
											artwork={artwork}
											key={artwork.id}
											status='aa'
										/>
									</Link>
								</Col>
							))}
						</Row>
					</div>
				)}

				{/* <button onClick={this._getAll.bind(this)}>Load</button> */}
			</div>
		);
	}
}

const withAllArtworks = connect(
	(state) => state,
	ArtWorkActions
)(ViewAllArtworks);
const FilterWithGlobalFilters = connect(
	(state) => state,
	GlobalFilterActions
)(CategoryFilter);
export default withAllArtworks;
