import React from 'react';
import Artwork from '../../repos/models/Artwork';
import { Card, Avatar, Row, Col } from 'antd';
import ArtworkComponent from '../ArtworkComponent' 
import ArtworkComponentWithoutImage from '../ArtworkComponentWithoutImage' 
import ArtworkComponentList from '../ArtworkComponentList'
import { imgBaseUrl } from '../../api.config'
const { Meta } = Card;

interface ArtworkCardProps {
    artwork: Artwork | any ,
    status : any
}

export class SmallArtworkCard extends React.Component<ArtworkCardProps>{
    render() {
        return (
            // <Card hoverable className="small-artwork-card-main-container">
            // </Card>
              <ArtworkComponent {...this.props.artwork } /> 
            
        );
    }
}

export class SmallArtworkCardWithoutImage extends React.Component<ArtworkCardProps>{
    render() {
        return (
            // <Card hoverable className="small-artwork-card-main-container">
            // </Card>
              <ArtworkComponentWithoutImage {...this.props.artwork } /> 
             
        );
    }
}

export class SmallArtworkCardList extends React.Component<ArtworkCardProps>{
    render() {
        return (
            // <Card hoverable className="small-artwork-card-main-container">
            // </Card>
            //   <ArtworkComponentWithoutImage {...this.props.artwork } /> 
            <ArtworkComponentList {...this.props.artwork } /> 
            
        );
    }
}

export class NotificationArtworkCard extends React.Component<ArtworkCardProps>{
    render() {
        const {user} = this.props.artwork;
        return (
            <div className="notification-artwork-card-container">
                <div className="notification-artwork-card-source">
                    <Avatar className="notification-artwork-card-avatar" src={this.props.artwork.user.imagePaths} size={37}/>
                </div>
                <Col className="notification-artwork-card-content">
                    <Row className="content-date">{ "Created By -" + `${user.name}`}</Row>
                    <Row className="content-header">#{this.props.artwork.id} · {this.props.artwork.code}</Row>
                    <Row className="content-reason">{this.props.artwork.reason}</Row>
                    <Row className="content-date-time">{this.props.artwork.create_on.replace("T", " ").split(".")[0]}</Row>
                </Col>
                <div className="notification-artwork-card-thumb" style={{backgroundImage: `url("${imgBaseUrl + this.props.artwork.activeImage}")`}}></div>
            </div>
        );
    }
}