import _ from 'lodash';
import { lionUserManager } from "./UserRepo";
import Filter, { INode, createRepo } from "../components/Filters/ColumnGallery";

export const normalizeMainCat = (data: any[]): any => data.map(({ main_cat }) => ({ sub: [...main_cat], type: { name: `Main Category` }, masterData: { name: `${main_cat[0].type.name}[s]` } }));

// const repo = normalizeMainCat(mock).map(createRepo);

export interface IFilter<T> {
    id: number
    filterDataRepo: T
    active: T
}

export type Filters = Array<IFilter<Array<INode<any>>>>

export const Repo = (
    state: Filters = [], action: {
        type: GlobalFilterActionTypes,
        payload: any
    }) => {
    switch (action.type) {
        case GlobalFilterActionTypes.APPLY_FILTER: {
            return action.payload
        }
        default: return state;
    }
}

export const GlobalFilterActions = (dispatch: any) => ({
    syncFilters: async () => {
        try {
            const categories = await lionUserManager.authenticationManager.get('/artwork/category/get');
            if (categories.data != null && categories.data.length > 0) {
                const _repo = normalizeMainCat(categories.data).map(createRepo)
                const repoCollection = _repo.map((repo: Array<INode<any>>, i: number) => ({
                    id: i,
                    filterDataRepo: repo,
                    active: [repo[0]]
                }))
                dispatch({
                    type: GlobalFilterActionTypes.APPLY_FILTER, payload: repoCollection
                });
            }
            // dispatch({ type: GlobalFilterActionTypes.RESET_FILTERS });
        } catch (err) { }
    },
    setFilter: (filter: Filter) => {
        dispatch({
            type: GlobalFilterActionTypes.APPLY_FILTER, payload: filter
        });
    }
})

export class GlobalFilterActionTypes {
    static APPLY_FILTER = 'APPLY_FILTER';
    // static UPDATE_FILTER = 'UPDATE_FILTER';
    static RESET_FILTERS = 'RESET_FILTERS';
    // static SYNC_FILTERS = 'SYNC_FILTERS';
}