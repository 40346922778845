import React, { Component } from 'react';
import eimsky from '../../../img/powered_by.png'
export class PoweredByFooter extends Component {
    render() {
        return (
            <div className="absolute powered-by-footer-container bottom flex-center">
                <img src={eimsky} className="powered-by-footer"/>
            </div>
        )
    }
} 