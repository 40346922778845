import React, { Component } from 'react'
import { INode, SelectableSlotContainerProps } from './module'
import { Row, Col, Button, Input } from 'antd'
import {
    SearchOutlined,
    CloseOutlined
} from '@ant-design/icons'
import SelectableListItem from './FilterItem'

export default class extends Component<SelectableSlotContainerProps<any>, any>{
    constructor(props: any) {
        super(props);
        this.onSearchChange = this.onSearchChange.bind(this)
        this._filter = this._filter.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.state = {
            searchText: "",
            isSearchBarVisible: false
        }
    }

    onSearchChange(text: string) {
        this.setState({
            searchText: text.toLowerCase()
        })
    }

    _filter(node: INode<any>) {
        try {
            return node.name.toLowerCase().match(this.state.searchText)
        } catch (err) {
            console.error(err)
        }
    }

    toggleSearchBar() {
        this.setState({
            isSearchBarVisible: !this.state.isSearchBarVisible
        })
    }

    render() {
        return (
            <Col>
                <Row className="header" align="middle" justify="start">
                    <Col flex="auto">{this.props.slot.slotType && this.props.slot.slotType}</Col>
                    <Col style={{ height: 24 }}>
                        {this.props.slot.children.length >= 4 && <Button size="small" type="link" onClick={this.toggleSearchBar}><SearchOutlined style={{ fontSize: 13 }} /></Button>}
                    </Col>
                </Row>
                {this.state.isSearchBarVisible && <Row style={{ margin: '10px 0' }}>
                    <Input size="small" onChange={({ target: { value } }) => this.onSearchChange(value)} addonAfter={() => <Button size="small" type="link" ><CloseOutlined /></Button>} />
                </Row>}
                <div className="item-container">
                    {
                        this.props.slot.children.filter(this._filter).map((node: INode<any>) => <SelectableListItem
                            onClick={() => this.props.itemProps.onClick(node, this.props.itemProps.index)}
                            node={node} index={this.props.itemProps.index}
                            renderChild={this.props.itemProps.renderChild}
                            key={node.id}
                        />)
                    }
                </div>
            </Col>
        )
    }
}