export class RouteConfig{
    static root = '/'

    static PROTECTED = '/protected'
    static PROTECTED_ROOT = '/protected/'
    static PROTECTED_MAIN_DASHBOARD = '/protected/dashboard'
    static PROTECTED_VIEW_ARTWORK = '/protected/view/:id'
    static PROTECTED_CREATE_ARTWORK = '/protected/artwork-create'
    static PROTECTED_VIEW_ALL_ARTWORKS = '/protected/view-all-artworks'
    static PROTECTED_VIEW_HELP = '/protected/help'
    static PROTECTED_UPLOAD_DOCS = '/protected/help-and-support'

    static LOGIN = '/'
    static VIEW_IMAGE = '/view_image/:id'
}

//this applies to breadcrumbs
export const rootComponent = "Home"
export const rootIcon = "home"

