import React, { Component } from 'react'
import { Row } from 'antd'
import { SelectableListItemProps } from './module';

export default class extends Component<SelectableListItemProps<any>, any>{
    render() {
        return (
            <Row onClick={this.props.onClick} className={this.props.className}>
                {this.props.renderChild && this.props.renderChild(this.props.node)}
            </Row>
        )
    }
}