import _ from "lodash";
import { texts } from "../texts.config";
import Joi from "@hapi/joi";
import ArtWork from "../repos/models/Artwork";
import { lionUserManager } from "./UserRepo";
import { Moment } from "moment";
// const moment = require('moment');

const schema = Joi.object({
	data: Joi.array().default([]),
});

export const Repo = (
	state = {
		selectedArtwork: null,
		artworkCount: null,
		dashboardData: null,
		notificationData: null,
		userTaskList: null,
		docs: null,
		userViseArtworkStatus: null,
		allArtWorks: [],
		artworkSummary: [],
	},
	action: { type: ArtWorkActionTypes; payload: any }
) => {
	switch (action.type) {
		case ArtWorkActionTypes.GET_ALL_ART_WORKS: {
			return { ...state, allArtWorks: action.payload };
		}
		case ArtWorkActionTypes.GET_ART_WORK_DETAILS: {
			return { ...state, selectedArtwork: action.payload };
		}
		case ArtWorkActionTypes.GET_USER_TASK_LIST: {
			return { ...state, userTaskList: action.payload };
		}
		case ArtWorkActionTypes.DASHBOARD_DATA_LIST: {
			return { ...state, dashboardData: action.payload };
		}
		case ArtWorkActionTypes.NOTIFICATION_DATA_LIST: {
			return { ...state, notificationData: action.payload };
		}
		case ArtWorkActionTypes.ARTWORK_DATA_SUMMARY: {
			return { ...state, artworkSummary: action.payload };
		}
		case ArtWorkActionTypes.ARTWORK_DATA_COUNT: {
			return { ...state, artworkCount: action.payload };
		}
		case ArtWorkActionTypes.DOCUMENTS: {
			return { ...state, docs: action.payload };
		}
		case ArtWorkActionTypes.GET_USER_VISE_ARTWORK_STATUS: {
			return { ...state, userViseArtworkStatus: action.payload };
		}

		default:
			return state;
	}
};

export const ArtWorkActions = (dispatch: any) => ({
	getAllArtWorks: async () => {
		try {
			// const res = await get('/artwork/mainVersion/get', { Authorization: "Bearer " + user.data.access_token });
			// if (res.status == 200) {
			//     console.log("res" ,res);
			//     if (schema.validate(res))
			//         dispatch({
			//             type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
			//             payload: res.data.map((artWork: {}) => new ArtWork({...artWork}))
			//         });
			// } else {
			//     throw new Error(texts.DEFAULT_ERROR_MESSAGE)
			// }

			dispatch({
				type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
				payload: [],
			});

			const res = await lionUserManager.authenticationManager.get(
				"/artwork/mainVersion/get"
			);

			dispatch({
				type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
				payload: res.data.map((artWork: {}) => new ArtWork({ ...artWork })),
			});
		} catch (err) {
			throw err;
		}
	},

	getArtwrorksById: async (id: string, page?: number) => {
		try {
			var res;
			var artworks;

			if (id == "") {
				res = await lionUserManager.authenticationManager.get(
					`/artwork/mainVersion/getPage/${page ?? 1}/8`
				);

				if (res.status == 200) {
					artworks = res.data.map((artWork: {}) => new ArtWork({ ...artWork }));
				} else throw new Error();
			} else {
				res = await lionUserManager.authenticationManager.get(
					`/artwork/mainVersion/gets/${id}`
				);

				if (res.status == 200) {
					artworks = res.data.map((artWork: {}) => new ArtWork({ ...artWork }));
				} else throw new Error();
			}

			dispatch({
				type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
				payload: artworks,
			});
		} catch (_) {
			dispatch({
				type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
				payload: [],
			});
		}
	},

	getFilteredArtworks: async (
		data: {}[],
		page_no: any,
		startDate: Moment,
		endDate: Moment
	) => {
		try {
			dispatch({
				type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
				payload: [],
			});
			var _startDate: any;
			var _endDate: any;

			const form = new FormData();

			const body = {
				mainVersionCategories: data,
			};
			form.append("mainVersion", JSON.stringify(body));

			if (startDate != null) {
				_startDate = new Date(startDate.format("YYYY-MM-DD"));
				_endDate = new Date(endDate.format("YYYY-MM-DD"));

				form.append("startDate", _startDate);
				form.append("endDate", _endDate);
			} else {
				_startDate = null;
				_endDate = null;
			}

			console.log(_startDate);
			console.log(_endDate);

			// form.append("startDate", startDate.format('YYYY-MM-DD'));
			// form.append("endDate", endDate.format('YYYY-MM-DD'));

			const res = await lionUserManager.authenticationManager.post(
				`/artwork/mainVersion/filterPageByDate/${page_no}/8`,
				form
			);
			console.log(res);
			dispatch({
				type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
				payload: res.data.map((artWork: {}) => new ArtWork({ ...artWork })),
			});
		} catch (err) {
			console.log({ err });
			throw err;
		}
	},
	getArtWorksByPage: async (page_no: any) => {
		try {
			dispatch({
				type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
				payload: [],
			});

			var url = "/artwork/mainVersion/getPage/" + page_no + "/8";
			const res = await lionUserManager.authenticationManager.get(url);
			console.log();
			if (res.status == 200) {
				dispatch({
					type: ArtWorkActionTypes.GET_ALL_ART_WORKS,
					payload: res.data.map((artWork: {}) => new ArtWork({ ...artWork })),
				});
			}
		} catch (err) {
			throw err;
		}
	},
	getArtWorksCount: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/artwork/mainVersion/getCount"
			);
			if (res.status == 200) {
				if (schema.validate(res))
					dispatch({
						type: ArtWorkActionTypes.ARTWORK_DATA_COUNT,
						payload: res.data,
					});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},
	getNotificationList: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/artwork/mainVersion/getPendingList/" + lionUserManager.user.data.USER_ID
			);
			if (res.status == 200) {
				if (schema.validate(res))
					dispatch({
						type: ArtWorkActionTypes.NOTIFICATION_DATA_LIST,
						payload: res.data,
					});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},
	getArtworkSummary: async (minorVersion_id: number) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/artwork/minorVersion/history/" + minorVersion_id
			);
			if (res.status == 200) {
				if (schema.validate(res))
					dispatch({
						type: ArtWorkActionTypes.ARTWORK_DATA_SUMMARY,
						payload: res.data,
					});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getArtwork: async (artWorkId: number) => {
		try {
			var minorVersion_id = null;

			const artwork_data = await lionUserManager.authenticationManager.get(
				`/artwork/mainVersion/get/${artWorkId}`
			);

			if (artwork_data.status == 200) {
				console.log("aaaaaaa" , artwork_data.data)
				for (var i = 0; i < artwork_data.data.minorVersions.length; i++) {
					if (artwork_data.data.minorVersions[i].active === true) {
						minorVersion_id = artwork_data.data.minorVersions[i].id;
					}
				}
				dispatch({
					type: ArtWorkActionTypes.GET_ART_WORK_DETAILS,
					// payload: res.data.map((artWork: {}) => new ArtWork({...artWork}))
					payload: artwork_data.data,
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}

			const userViseStatus = await lionUserManager.authenticationManager.get(
				`/artwork/change/getCheckList/${minorVersion_id}/${lionUserManager.user.data.USER_ID}`
			);

			if (userViseStatus.status == 200) {
				console.log("vvv" , userViseStatus)
				dispatch({
					type: ArtWorkActionTypes.GET_USER_VISE_ARTWORK_STATUS,
					payload: userViseStatus.data ? userViseStatus.data.toUpperCase() : "N/A",
				});
			} else {
				dispatch({
					type: ArtWorkActionTypes.GET_USER_VISE_ARTWORK_STATUS,
					payload: "N/A",
				});
			}

			if (minorVersion_id != undefined) {
				const response = await lionUserManager.authenticationManager.get(
					"/artwork/approval/getCheckList/" +
						minorVersion_id +
						"/" +
						+lionUserManager.user.data.USER_ID
				);
				console.log("response", response);

				if (response.status == 200) {
					if (schema.validate(response)) console.log("response.data", response.data);
					dispatch({
						type: ArtWorkActionTypes.GET_USER_TASK_LIST,
						// payload: res.data.map((artWork: {}) => new ArtWork({...artWork}))
						payload: response.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}

				try {
					const res = await lionUserManager.authenticationManager.get(
						"/artwork/minorVersion/history/" + minorVersion_id
					);
					console.log("res ", res);
					if (res.status == 200) {
						if (schema.validate(res))
							dispatch({
								type: ArtWorkActionTypes.ARTWORK_DATA_SUMMARY,
								payload: res.data,
							});
					} else {
						throw new Error(texts.DEFAULT_ERROR_MESSAGE);
					}
				} catch (err) {
					throw err;
				}
			}
		} catch (err) {
			throw err;
		}
	},

	getUploadedDocuments: async (minorVersion_id: number) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/artwork/document/getDoc/"
			);
			console.log(res);
			if (res.status == 200) {
				if (schema.validate(res))
					dispatch({
						type: ArtWorkActionTypes.DOCUMENTS,
						payload: res.data,
					});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	sendEmail: async (data: any) => {
		console.log(data);
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/artwork/minorVersion/share",
				data
			);

			if (res.status == 200) {
				return true;
			} else {
				return false;
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			return false;
			throw err;
		}
	},
});

export class ArtWorkActionTypes {
	static GET_ALL_ART_WORKS = "GET_ALL_ART_WORKS";
	static GET_ART_WORK_DETAILS = "GET_ART_WORK_DETAILS";
	static JOIN_ARTWORKS = "JOIN_ARTWORKS";
	static GET_USER_TASK_LIST = "TASK_LIST";
	static DASHBOARD_DATA_LIST = "DASHBOARD_DATA_LIST";
	static NOTIFICATION_DATA_LIST = "NOTIFICATION_DATA_LIST";
	static ARTWORK_DATA_SUMMARY = "ARTWORK_DATA_SUMMARY";
	static ARTWORK_DATA_COUNT = "ARTWORK_DATA_COUNT";
	static GET_USER_VISE_ARTWORK_STATUS = "GET_USER_VISE_ARTWORK_STATUS";
	static DOCUMENTS = "DOCUMENTS";
}
