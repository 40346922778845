import React, { Component , FunctionComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux'
import { GlobalFilterActions, Filters, IFilter } from '../repos/GlobalFilterRepo'
import { lionUserManager } from '../repos/UserRepo'
import { message, Form, Upload, Button, Input, Typography, Row, Col, Card, Tooltip } from 'antd';
import { AxiosResponse } from 'axios';
import { texts } from '../texts.config';
import { withRouter } from 'react-router';
import { FolderOutlined, SubnodeOutlined, FolderOpenOutlined, LoadingOutlined, CheckOutlined, ExclamationOutlined } from '@ant-design/icons'
import { UploaderState, FileDataMapper, SingleFileUploader, MultipleFileUploader, defaultImageExtensions } from '../components';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { CategoryFilter } from '../components'
import { INode } from '../components'
import   doc   from '../../img/doc.png'
import { Select } from 'antd';
import { ArtWorkActions } from '../repos/ArtWorksRepo'
import { Link } from 'react-router-dom'
const { Option } = Select;

const { Title, Paragraph, Text } = Typography;
interface CreateArtWorkPageState {
    file: RcFile | undefined,
    filterData: [],
    description: string,
    reason: string,
    fileMapperData: UploaderState
    isLimitedEdition : boolean
    validation: {
        filters: boolean,
        reason: boolean,
        comment: boolean,
        file: boolean
    }
}
interface ValidationLog{
    isValid: boolean,
    category: string
}
class UploadDocuments extends React.Component<any, CreateArtWorkPageState>{
    constructor(props: any) {
        super(props);
        this.onMultipleUploaderSubmit = this.onMultipleUploaderSubmit.bind(this)
           this.state = {
            file: undefined,
            filterData: [],
            description: "",
            reason: "",
            isLimitedEdition : false,
            fileMapperData: {
                files: [],
                data: []
            },
            validation: {
                filters: false,
                reason: true,
                comment: true,
                file: false
            },
           
        };
    }

    async componentDidMount(){
       await this.props.getUploadedDocuments()
    }


    versionTypeChange = (value : any) => { 
        value == 1 ? this.setState({isLimitedEdition : true}):  this.setState({isLimitedEdition : false});
 
    }

    
 
 

    async _createArtwork() {
             
        var hide;
        try {
              if(this.state.fileMapperData.files.length == 0){
                  message.error("No document to upload.");
                 return;
              }
            

                 hide = message.loading('Action in progress..', 0);
 
        

                const form = new FormData();

         

                this.state.fileMapperData.files.forEach((element: any) => {
                    form.append("file", element);
                });
 
                const res: AxiosResponse = await lionUserManager.authenticationManager.post(
                    '/artwork/document/uploadDoc',
                    form
                )

                console.log(res)

                if (res.status == 201) {
                     message.success("Successfully Uploaded");
                     window.location.reload() 

                } else {
                    console.log("Create Error Log", res)
                    throw new Error(texts.ARTWORK_CREATION_ERROR)
                }
             
        } catch (err) {
            message.error(err.message);
        } finally {
            hide && hide()
        }
    }

  

    onMultipleUploaderSubmit(data: UploaderState) {
        this.setState({
            fileMapperData: data
        })
    }

    onDescriptionChange(value: string) {
        if (!(value.length > 250))
            this.setState({
                description: value
            })
        else message.warn("Description is too large")
    }

    onReasonChange(value: string) { 
        this.setState({
            reason: value
        })
    }

    render() {
        const props = {

            onRemove: (file: any) => {
                this.setState({ file });
            },
            beforeUpload: (file: any) => {

                if (this.state.file == undefined) {
                    this.setState({ file });

                }
                else {
                    message.error("You Can Not Upload Multyple Files..");
                }

                return false;
            },
            accept: ".jpeg,.png",
            multiple: false,

        }
        // const mainCats = normalizeMainCat(this.props.GlobalFilterRepo.filters).map(normalize);
        return (
            <div>
     
                 { <Row style={{ marginTop: 15 }} gutter={[10, 10]}>
                {
                  this.props.ArtworkRepo.docs &&  this.props.ArtworkRepo.docs.length > 0 && this.props.ArtworkRepo.docs.reverse().map((artwork: any) =>
                  <Tooltip title={artwork.name}>
                       <Col   xl={3} xs={3} sm={3} lg={3} key={artwork.id}>
                       <a href={"https://lionapi.eimsky.com/artwork/downloadFile/documents/" + artwork.name}>
                            <div className="doc-container">
                                     <img src={doc} alt="Snow" style={{ width: "100%" ,  height:"80%" }} />
                                    <p style={{marginLeft:20 , color:"#000"}}>{artwork.name.substring(0, 22)}</p>
                                  </div>
                            </a>
                        </Col>
                        </Tooltip>
                    )
                }
            </Row>}

            <Form className="ant-advanced-search-form">
              
 
                <Form.Item>
                    <div>
               

                        <MultipleFileUploader text="Upload documents" accept={defaultImageExtensions} onChange={this.onMultipleUploaderSubmit} />
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" shape="round" htmlType="submit" onClick={this._createArtwork.bind(this)}>Upload Document</Button>
                </Form.Item>
            </Form>
            </div>
        );
    }
}

const WrappedArtWorkView = UploadDocuments;
const withArtworksRepo = connect(((state: any) => state), ArtWorkActions)(WrappedArtWorkView);
export default withRouter(withArtworksRepo as FunctionComponent);
// const withFilter = connect((state => state), UserRepoActions)(_CreateArtWorkPage);
//export default withRouter(connect((state => state), GlobalFilterActions)(UploadDocuments) as React.FC)
