import React, { Component } from 'react';
import { INode, SelectableListProps, SelectableListState, SelectableSlot } from './module'
import { Row } from 'antd'
import SelectableSlotContainer from './FilterItemSlot'

const toSelectableSlotItem = (nodes: Array<INode<any>>): SelectableSlot<any> => (nodes && nodes.length > 0) ? ({ slotType: nodes[0].type, children: nodes }): ({ slotType: "", children: [] })

export default class extends Component<SelectableListProps<INode<any>>, SelectableListState<any>> {

    constructor(props: any) {
        super(props);
        this.onNodeSelect = this.onNodeSelect.bind(this)
        this.renderNodeSlot = this.renderNodeSlot.bind(this)
        this.state = {
            renderableNodeTree: [toSelectableSlotItem(this.props.rootCollection)]
        }
    }

    onNodeSelect(node: INode<any>, index: number) {
        this.setState((prevState: SelectableListState<any>) => {

            const _children = this.props.getChildren && this.props.getChildren(node)

            if (_children) {
                prevState.renderableNodeTree = prevState.renderableNodeTree.slice(0, ++index)
                _children.length > 0 && prevState.renderableNodeTree.push({
                    slotType: _children[0].type,
                    children: _children
                })
            }

            return prevState
        }, () => this.props.onSelect && this.props.onSelect(node))
    }

    renderNodeSlot(slot: SelectableSlot<any>, index: number) {
        return (
            <SelectableSlotContainer
                slot={slot}
                key={slot.slotType}
                itemProps={{
                    index: index,
                    renderChild: this.props.renderChild,
                    onClick: this.onNodeSelect
                }}
            />
        )
    }

    render() {
        return (
            <Row className="filter-main-container" gutter={10}>
                {this.state.renderableNodeTree.map(this.renderNodeSlot)}
            </Row>
        )
    }
}