// id: 22
// mainVersionCategories: (2) [{…}, {…}]
// minorVersions: [{…}]
// user_id: 4
// create_on: "2020-01-29T10:43:03.000+0000"
// code: "LI-LRG"
// description: "test description"
// reason: "test_reason"
// is_main_version_lock: true
// is_active: true

export default class Artwork {
	id: number;
	artworkVersion: string;
	create_on: string;
	description: string;
	reason: string;
	activeImage: string;
	image: any;
	minorVersions: any;
	user: any;
	isMainVersionLock: boolean;
	isReject: boolean;
	isLimitedEdition: boolean;
	isActive: boolean;
	constructor(props: any) {
		const {
			create_on,
			description,
			reason,
			code,
			img,
			minorVersions,
			id,
			activeImage,
			user,
			isMainVersionLock,
			isReject,
			isLimitedEdition,
			isActive,
		} = props;
		this.artworkVersion = code;
		this.create_on = create_on;
		this.isActive = isActive;
		this.description = description;
		this.reason = reason;
		this.image = img;
		this.minorVersions = minorVersions;
		this.id = id;
		this.activeImage = activeImage;
		this.user = user;
		this.isMainVersionLock = isMainVersionLock;
		this.isReject = isReject;
		this.isLimitedEdition = isLimitedEdition;
	}
}

// export  class MinorVersions {
//     original_image: string;
//     constructor(props: any) {
//         const { original_image } = props;
//         this.original_image = original_image;
//     }
// }
