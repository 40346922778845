import React from "react";
import { Button, Row, Col, Card, Typography, PageHeader, Tag } from "antd";
import { texts } from "../texts.config";
import Artwork from "../repos/models/Artwork";
import MinorVersions from "../repos/models/MinorVersions";
import { imgBaseUrl } from "../api.config";
import "./ArtworkComponent.css";
const { Meta } = Card;
const { Title } = Typography;

export default class ArtworkComponentWithoutImage extends React.Component<
	Artwork,
	{}
> {
	renderArtworkStatusBadge = () => {
		console.log({artwork: this.props})
		if (this.props.isMainVersionLock) {
			return <Tag color="#52c41a" >COMPLETED</Tag>;
		}

		if (this.props.isReject && !this.props.isActive) {
			return <Tag color="#f5222d" >REJECTED COMPLETELY</Tag>;
		}

		if (this.props.isReject) {
			return <Tag color="#f5222d" >REJECTED</Tag>;
		}

		return <Tag color="#ffc440" >PENDING</Tag>;
	};

	render() {
		return (
			<Card
				className='artwork-card-container'
				style={{ height: "100%" }}
				hoverable>
				{/* {this.props.isMainVersionLock ? <Tag color="#2feb3d">Completed</Tag> :
          !this.props.isMainVersionLock && this.props.isReject ? <Tag color="#f91823">Rejected</Tag> : <Tag color="#ebd42f">Pending</Tag>} */}

				<Row>
					<Col flex='auto'>
						{/* {this.props.isMainVersionLock ? (
							<Tag color='#2feb3d'>Completed</Tag>
						) : !this.props.isMainVersionLock && this.props.isReject ? (
							<Tag color='#f91823'>Rejected</Tag>
						) : (
							<Tag color='#ebd42f'>Pending</Tag>
						)} */}
						{this.renderArtworkStatusBadge()}
					</Col>
					<Col>
						<Tag><b>{this.props.id}</b></Tag>
					</Col>
				</Row>

				<div className='artwork-card-title' style={{ marginTop: 10 }}>
					{/* <Row>
            <Col className="artwork-card-main">{this.props.status}</Col>
          </Row> */}
					<Row>
						<Col className='artwork-card-main-without-image'>
							{this.props.artworkVersion}
						</Col>
					</Row>
					<Row>
						<Col className='artwork-card-date'>
							{this.props.create_on.replace("T", " ").split(".")[0]}
						</Col>
					</Row>
				</div>
			</Card>
		);
	}
}
