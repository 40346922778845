import React from "react";
import { Button, Row, Col, Card, Typography, PageHeader, Tag } from "antd";
import { texts } from "../texts.config";
import Artwork from "../repos/models/Artwork";
import MinorVersions from "../repos/models/MinorVersions";
import { imgBaseUrl } from "../api.config";
import "./ArtworkComponent.css";
const { Meta } = Card;
const { Title } = Typography;

export default class ArtworkComponentList extends React.Component<Artwork, {}> {
	renderArtworkStatusBadge = () => {
		console.log({artwork: this.props})
		if (this.props.isMainVersionLock) {
			return <Tag color="#52c41a" >COMPLETED</Tag>;
		}

		if (this.props.isReject && !this.props.isActive) {
			return <Tag color="#f5222d" >REJECTED COMPLETELY</Tag>;
		}

		if (this.props.isReject) {
			return <Tag color="#f5222d" >REJECTED</Tag>;
		}

		return <Tag color="#ffc440" >PENDING</Tag>;
	};

	render() {

		return (
			<div className='artwork-list-card' style={{ marginTop: 10 }}>
				<Row>
					<Col span={1} style={{ color: "#000" }}>
						**
					</Col>
					<Col span={8} style={{ color: "#888888" }}>
						{this.props.artworkVersion}
					</Col>

					<Col span={4} style={{ color: "#888888" }}>
						{this.props.create_on.split("T")[0]}
					</Col>
					<Col span={5} style={{ color: "#888888" }}>
						{" "}
						Reason :{" "}
						{this.props.reason.trim() != "" ? (
							this.props.reason
						) : (
							<i>No Reason Is Provides By Creator</i>
						)}
					</Col>
					<Col span={3} style={{ color: "#888888" }}>
						{" "}
						{this.props.isLimitedEdition == true
							? "Limited Edition"
							: "Regular Design"}
					</Col>
					{/* <Col span={2}>{this.props.isMainVersionLock ? <Tag color="#2feb3d">Completed</Tag> :
          !this.props.isMainVersionLock && this.props.isReject ? <Tag color="#f91823">Rejected</Tag> : <Tag color="#ebd42f">Pending</Tag>}</Col> */}

					<Row>
						<Col flex='auto'>
							{/* {this.props.isMainVersionLock ? (
								<Tag color='#2feb3d'>Completed</Tag>
							) : !this.props.isMainVersionLock && this.props.isReject ? (
								<Tag color='#f91823'>Rejected</Tag>
							) : (
								<Tag color='#ebd42f'>Pending</Tag>
							)} */}
							{this.renderArtworkStatusBadge()}
						</Col>
						<Col>
							<Tag>
								<b>{this.props.id}</b>
							</Tag>
						</Col>
					</Row>
				</Row>
			</div>
		);
	}
}
