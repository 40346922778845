import React, { FunctionComponent } from "react";
import { Table, Tag, message, Spin, Drawer } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ArtWorkActions } from "../repos/ArtWorksRepo";
import { RouteConfig } from "../route.config";
import { imgBaseUrl, orgImageBaseUrl } from "../api.config";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import logo from '../logo.png'
import "./style.css";

const { Column } = Table;
var image: any;

class ViewImage extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			claims: [],
			loading: false,
			visibleView: false,
			image: ""
		};
	}

	componentDidMount() {
		image = this.props.match.params.id;
		this.setState({ image: image });
	}

	render() {
		return (
			<div
				style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<TransformWrapper
					// defaultScale={0.1}
					// options={{
					// 	centerContent: true,
					// 	limitToBounds: true,
					// 	limitToWrapper: true
					// }}
                    >
					<TransformComponent>
						{this.state.image && (
							<img
								alt='example'
								style={{ height: 650 }}
								src={orgImageBaseUrl + this.state.image}
							/>
						)}
					</TransformComponent>
				</TransformWrapper>
			</div>
		);
	}
}

const WrappedViewImage = ViewImage;

const withClaimRepo = connect(
	(state: any) => state,
	ArtWorkActions
)(WrappedViewImage);

export default withClaimRepo;
