
 

export function getTemplate(aa : string){

    const tempComponent = `<!DOCTYPE html>
                                    <html>
                                    
                                    <head>
                                        <style>
                                            table {
                                                width: 100%;
                                            }
                                            
                                            table,
                                            th,
                                            td {
                                                border: 1px solid black;
                                                border-collapse: collapse;
                                            }
                                            
                                            th,
                                            td {
                                                padding: 5px;
                                                text-align: left;
                                            }
                                            
                                            table.names tr:nth-child(even) {
                                                background-color: #eee;
                                            }
                                            
                                            table.names tr:nth-child(odd) {
                                                background-color: #fff;
                                            }
                                            
                                            table.names th {
                                                background-color: black;
                                                color: white
                                            }
                                        </style>
                                    </head>
                                    
                                    <body>
                                    
                                        <h2>Floating Images</h2>
                                        <p><strong>Float the image to the right:</strong></p>
                                    
                                        <p>
                                            <img src="smiley.gif" alt="Smiley face" style="float:right;width:42px;height:42px;"> A paragraph with a floating image. A paragraph with a floating image. A paragraph with a floating image.
                                        </p>
                                    
                                        <p><strong>Float the image to the left:</strong></p>
                                        <p>
                                            <img src="smiley.gif" alt="Smiley face" style="float:left;width:42px;height:42px;"> A paragraph with a floating image. A paragraph with a floating image. A paragraph with a floating image.
                                        </p>
                                        <table>
                                            <tr>
                                                <th>Firstname</th>
                                                <th>Lastname</th>
                                                <th>Age</th>
                                            </tr>
                                            <tr>
                                                <td>Jill</td>
                                                <td>Smith</td>
                                                <td>50</td>
                                            </tr>
                                            <tr>
                                                <td>Eve</td>
                                                <td>Jackson</td>
                                                <td>94</td>
                                            </tr>
                                            <tr>
                                                <td>John</td>
                                                <td>Doe</td>
                                                <td>80</td>
                                            </tr>
                                        </table>
                                    
                                        <br>
                                    
                                        <table class="names">
                                            <tr>
                                                <th>Firstname</th>
                                                <th>Lastname</th>
                                                <th>Age</th>
                                            </tr>
                                            <tr>
                                                <td>Jill</td>
                                                <td>Smith</td>
                                                <td>50</td>
                                            </tr>
                                            <tr>
                                                <td>Eve</td>
                                                <td>Jackson</td>
                                                <td>94</td>
                                            </tr>
                                            <tr>
                                                <td>John</td>
                                                <td>Doe</td>
                                                <td>80</td>
                                            </tr>
                                        </table>
                                    </body>
                                    
                                    </html>`;

    return  tempComponent

}                           