import React, { Component, FunctionComponent } from "react";
import {
	Form,
	Input,
	Row,
	Col,
	Card,
	Tooltip,
	Typography,
	Checkbox,
	Divider,
	Button,
	Modal,
	Tag,
	Spin,
	PageHeader,
	message,
	Empty,
	notification,
	Upload,
	Select,
	Drawer,
	Descriptions,
	Badge,
	Statistic,
} from "antd";
import { withRouter } from "react-router";
import { ArtWorkActions } from "../repos/ArtWorksRepo";
import { connect } from "react-redux";
import TaskList from "../repos/models/TaskList";
import ApproveLevelRoleInvolvements from "../repos/models/ApproveLevelRoleInvolvements";
import RejectedTasks from "../repos/models/RejectedTasks";
import VersionSummary from "../repos/models/Summary";
import { AxiosResponse } from "axios";
import { imgBaseUrl, baseUrl, orgImageBaseUrl } from "../api.config";
import MinorVersions from "../repos/models/MinorVersions";
import { lionUserManager } from "../repos/UserRepo";
import {
	UploaderState,
	FileDataMapper,
	MultipleFileUploader,
	SingleFileUploader,
	defaultImageExtensions,
} from "../components";
import PrintComponent from "../components/PrintComponent";
import {
	InboxOutlined,
	SnippetsOutlined,
	MailOutlined,
	DownloadOutlined,
	FileOutlined,
	MinusCircleOutlined,
	PictureOutlined,
	FormOutlined,
	PrinterOutlined,
	RetweetOutlined,
	LikeOutlined,
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { getTemplate } from "../components/GenerateDoc/temp";
import { Link } from "react-router-dom";

import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import logo_word from "../../img/word.png";

const { Option } = Select;
const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;

let htmlString: any = "";
var approveLevel: any[] = [];
var selected_value: any[] = [];
var _task_list: any[] = [];
var docs: any = false;
var sub_image_count: 0;
var sub_images: any[] = [];
var checkedImageList: any[] = [];

class ViewArtWork extends React.Component<
	any,
	| {
			files: UploaderState;
	  }
	| any
> {
	componentRef!: Component<any, {}, any> | Element | null;
	constructor(props: any) {
		super(props);
		this.onUploaderSubmit = this.onUploaderSubmit.bind(this);
		this.setArtwork = this.setArtwork.bind(this);
		this.showModal = this.showModal.bind(this);
		this.state = {
			expand: false,
			loading: false,
			visible: false,
			visible1: false,
			visible_resubmit_modal: false,
			confirmLoading: false,
			file: {},
			comment: "",
			reason: "",
			minervirson_visible: false,
			enableDoc: false,
			visible_summary_modal: false,
			minorVersions: {},
			version_id: Number,
			show_image_uploder: false,
			visible_comments: false,
			isApproveImageMandatory: false,
			visible_email: false,
			task_list: [],
			checkedSubImages: false,
			loading_email: false,
			emailReceiver: "",
			emailCc: "",
			emailBcc: "",
			emailSubject: "",
			emailContent: "",
			receiverEmailError: false,
			ccEmailError: false,
			bccEmailError: false,
			fileMapperData: {
				files: [],
				data: [],
			},
		};
	}

	showDrawer = () => {
		this.setState({
			visible1: true,
		});
	};

	onClose = () => {
		this.setState({
			visible1: false,
			visible_summary_modal: false,
			visible_comments: false,
			visible_email: false,
		});
	};

	handleChange = (value: any) => {
		var el = selected_value.find((a) => a.includes(value.split("_")[0]));
		if (el === undefined) {
			selected_value.push(value);
		} else {
			var index = selected_value.indexOf(
				selected_value.find((a) => a.includes(value.split("_")[0]))
			);
			selected_value.splice(index, 1);
			selected_value.push(value);
		}
	};

	async _changeApprovalStatus() {
		try {
			var taskList = selected_value.map((value) => ({
				id: value.split("_")[0],
				approvalStatus: value.split("_")[1],
			}));

			console.log("taskList" , taskList);

			var isReject = taskList
				.map((value) => value.approvalStatus)
				.reduce((prev, cur) => prev || cur === "0", false);

			var isCompleteReject = taskList
				.map((value) => value.approvalStatus)
				.reduce((prev, cur) => prev || cur === "-2", false);


				 

			/// Comment validation
			if (isReject || isCompleteReject)
				if (Object.keys(this.state.comment).length == 0) {
					message.error("Rejected Reason Cannot Be Blank");
					return;
				}

			/// Attachement validation
			/// document validation should perform when not a rejecting
			if (!isReject && !isCompleteReject)
				if (
					this.state.isApproveImageMandatory &&
					Object.keys(this.state.file).length == 0
				) {
					message.error("Attachment Cannot Be Blank");
					return;
				}

			/// Complete reject
			if (isCompleteReject) {
				if (Object.keys(this.state.comment).length == 0) {
					message.error("Complete Rejected Reason Cannot Be Blank");
					return;
				}

				var completeRejectForm = new FormData();
				completeRejectForm.append("file", this.state.file);
				completeRejectForm.append("id", this.props.match.params.id);
				completeRejectForm.append("user_id", lionUserManager.user.data.USER_ID);
				completeRejectForm.append(
					"data",
					JSON.stringify({
						// treat task list as normal reject status "0"
						taskList: taskList.map((value) => ({ ...value, approvalStatus: "0" })),
						comment: this.state.comment,
					})
				);


				 

				setTimeout(() => {
					this.setState({
						visible: false,
						confirmLoading: false,
						minervirson_visible: false,
						visible_resubmit_modal: false,
						loading: true,
					});
				}, 2000);

				const res: AxiosResponse = await lionUserManager.authenticationManager.post(
					`/artwork/change/completeReject/${this.props.match.params.id}`,
					// `/artwork/change/completeReject`,
					completeRejectForm
				);

				if (res.status === 200) {
					window.location.reload(true);
				} else {
					throw new Error("Error");
				}

				return;
			}

			/// Normal state change
			var form = new FormData();
			form.append("file", this.state.file);
			form.append("user_id", lionUserManager.user.data.USER_ID);
			form.append(
				"data",
				JSON.stringify({
					taskList: taskList,
					comment: this.state.comment,
				})
			);

			this.setState({
				confirmLoading: true,
			});

			setTimeout(() => {
				this.setState({
					visible: false,
					confirmLoading: false,
					minervirson_visible: false,
					visible_resubmit_modal: false,
					loading: true,
				});
			}, 2000);

			const res: AxiosResponse = await lionUserManager.authenticationManager.post(
				"/artwork/approval/changeApprovalStatus",
				form
			);

			if (res.status == 200) {
				window.location.reload(true);
			} else {
				throw new Error("Error");
			}
		} catch (err) {
			console.log(err);
			message.error("your request has been processed. Please check in a while.");
			setTimeout(() => window.location.replace("../"), 3000)
		}
	}

	async _reSubmitArtwork() {
		try {
			if (Object.keys(this.state.file).length === 0) {
				message.error("Please Attach your image");
				return;
			} else {
				this.setState({
					confirmLoading: true,
				});
				setTimeout(() => {
					this.setState({
						visible: false,
						confirmLoading: false,
						inervirson_visible: false,
						visible_resubmit_modal: false,
						loading: true,
					});
				}, 2000);
				var form = new FormData();
				form.append("artwork", this.state.file);
				form.append("reason", this.state.reason);
				form.append("mainVersionId", this.props.match.params.id);

				this.state.fileMapperData.files.forEach((element: any) => {
					form.append("imageList", element);
				});

				form.append(
					"imageDetails",
					JSON.stringify(
						this.state.fileMapperData.data.map((data: FileDataMapper) => ({
							thumbnail: data.file,
							comment: data.comment,
							imageUrl: data.url,
						}))
					)
				);

				console.log(
					this.state.fileMapperData.data.map((data: FileDataMapper) => ({
						thumbnail: data.file,
						comment: data.comment,
						imageUrl: data.url,
					}))
				);

				const res = await lionUserManager.authenticationManager.post(
					"/artwork/minorVersion/create",
					form
				);
				if (res.status == 200) {
					window.location.reload(true); 
					// history.push(`view/${id}`)
				} else {
					throw new Error("Error");
				}
			}
		} catch (err) {
			message.error(err.message);
		}
	}

	componentDidUpdate(prevProp: any) {
		if (this.props.match.params.id !== prevProp.match.params.id) {
			window.location.reload(true);

			this.setArtwork(this.props.match.params.id);
			this.setState({ loading: true });
		}
	}

	async setArtwork(id: number) {
		try {
			console.log(id);
			 await this.props.getArtwork(id);

			console.log(this.props.ArtworkRepo.userTaskList);

			this.setState({
				isApproveImageMandatory:
					this.props.ArtworkRepo.userTaskList.isApproveImageMandatory,
			});

			_task_list = [];
			if (this.props.ArtworkRepo.userTaskList.ckeckList.length > 0) {
				this.props.ArtworkRepo.userTaskList.ckeckList.forEach(function (
					element: any
				) {
					if (!element.approvalLevelRoleCheckList.cantReject) {
						approveLevel.push(
							{ key: "-1", value: "Pending" },
							{ key: "1", value: element.approvalLevelRoleCheckList.positiveFeedback },
							{
								key: "0",
								value: element.approvalLevelRoleCheckList.negativeFeedback,
							}
						);
					} else {
						docs = true;
						approveLevel.push(
							{ key: "-1", value: "Pending" },
							{ key: "1", value: element.approvalLevelRoleCheckList.positiveFeedback }
						);
					}

					if (element.approvalLevelRoleCheckList.id === 14)
						approveLevel.push({
							key: "-2",
							value: "Reject Completely",
						});

					_task_list.push({
						approvalStatus: element.approvalStatus.toString(),
						id: element.id,
						task_title: element.approvalLevelRoleCheckList.title,
						task_description: element.approvalLevelRoleCheckList.description,
						approveLevel: approveLevel,
					});

					approveLevel = [];
				});
				this.setState({ task_list: _task_list });
				this.setState({ enableDoc: docs });
			}
		} catch (err) {
			// message.error(err.message);
		} finally {
			if (this.props.ArtworkRepo.selectedArtwork.isReject != true) {
				sub_images = this.props.ArtworkRepo.selectedArtwork.minorVersions.filter(
					(minorVersions: any) => minorVersions.active == true
				)[0].imageses;
				sub_images.push({
					comment: "Main Image",
					imageUrl: "",
					thumbnail: this.props.ArtworkRepo.selectedArtwork.activeImage,
				});
				// console.log(sub_images);
				sub_image_count =
					this.props.ArtworkRepo.selectedArtwork.minorVersions.filter(
						(minorVersions: any) => minorVersions.active == true
					)[0].imageses.length;
			}
			this.setState({ loading: false });
		}
	}

	CheckedSubImages = (e: any) => {
		if (e.target.checked) {
			if (!checkedImageList.includes(e.target.value)) {
				checkedImageList.push(e.target.value);
			}
		} else {
			checkedImageList = checkedImageList.filter(
				(value: any) => value !== e.target.value
			);
		}

		console.log(checkedImageList);
		console.log(sub_image_count);

		if (checkedImageList.length == sub_image_count) {
			this.setState({ checkedSubImages: true });
		} else {
			this.setState({ checkedSubImages: false });
		}
	};

	showImageView(image: any) {
		//window.open('https://lion-artwork.eimsky.com/view_image/'+ image);

		// window.open(`${baseUrl}/view_image/` + image,"artwork",'height=1200,width=750');
		window.open(
			`${window.location.origin}/view_image/` + image,
			"artwork",
			"height=1200,width=750"
		);
	}

	componentDidMount() {
		checkedImageList = [];
		console.log(lionUserManager.user);
		this.setState({ loading: true });
		const { id } = this.props.match.params;
		if (id != null) {
			this.setArtwork(id);
		}

		htmlString = getTemplate("");
	}

	copyurl(url: any) {
		navigator.clipboard.writeText(url);
		notification["success"]({
			message: "URL COPIED TO CLIPBOARD",
			description: "",
		});
	}

	sendEmail = () => {
		this.setState({ visible_email: true });
	};

	viewMinerVersion(minorVersions: any) {
		this.setState({
			minervirson_visible: true,
			visible1: true,
			minorVersions: minorVersions,
		});
	}

	async viewSummary(minorVersions: any) {
		var hide;
		try {
			hide = message.loading("Action in progress..", 0);
			await this.props.getArtworkSummary(minorVersions.id);
			this.setState({
				visible_summary_modal: true,
			});
		} catch (err) {
			message.error(err.message);
		} finally {
			hide && hide();
		}
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	showModalResubmit = () => {
		this.setState({
			visible_resubmit_modal: true,
		});
	};

	async showModalSummaryDetails() {
		var hide;
		try {
			hide = message.loading("Action in progress..", 0);
			var selectedArtwork = this.props.ArtworkRepo.selectedArtwork.minorVersions;
			var minorversion_id = null;

			for (const [index, value] of selectedArtwork.entries()) {
				if (value.active == true) {
					minorversion_id = value.id;
				}
			}

			//   console.log(minorversion_id);
			if (minorversion_id != null) {
				await this.props.getArtworkSummary(minorversion_id);

				this.setState({
					visible_summary_modal: true,
				});
			} else {
				message.error("No Summary For This Version");
			}
		} catch (err) {
			message.error(err.message);
		} finally {
			hide && hide();
		}
	}

	async showComments() {
		this.setState({ visible_comments: true });
	}

	commentChange = (event: any) => {
		this.setState({ comment: event.target.value });
	};

	reasonChange = (event: any) => {
		this.setState({ reason: event.target.value });
	};

	handleOk = () => {
		this._changeApprovalStatus();
	};

	handleResubmit = () => {
		this._reSubmitArtwork();
	};

	handleCancel = () => {
		this.setState({
			visible: false,
			visible_resubmit_modal: false,
			minervirson_visible: false,
			visible_summary_modal: false,
		});
	};

	onUploaderSubmit(data: UploaderState) {
		this.setState({
			fileMapperData: data,
		});
	}

	resubmitModal = () => {
		const props = {
			onRemove: (file: any) => {
				this.setState({ file });
			},
			beforeUpload: (file: any) => {
				this.setState({ file });
				return false;
			},
			multiple: false,
		};

		const modalSublit = (
			<Modal
				title='Resubmit Artwork'
				visible={this.state.visible_resubmit_modal}
				onOk={this.handleResubmit}
				okText='Submit'
				confirmLoading={this.state.confirmLoading}
				onCancel={this.handleCancel}>
				<div>
					<Form.Item>
						<Form.Item style={{ marginTop: 20 }}>
							<TextArea
								rows={4}
								defaultValue={this.state.reason}
								onChange={this.reasonChange}
								placeholder='Resubmit Reason'
							/>
						</Form.Item>
						<div>
							<SingleFileUploader
								accept={defaultImageExtensions}
								onChange={(file: any) => this.setState({ file })}
							/>
							<MultipleFileUploader
								accept={defaultImageExtensions}
								onChange={this.onUploaderSubmit}
							/>
						</div>
					</Form.Item>
				</div>
			</Modal>
		);

		return modalSublit;
	};

	async saveDocx() {
		const fileData: any = await asBlob(htmlString); // asBlob() return Promise<Blob|Buffer>
		saveAs(fileData, "file.docx"); // save as docx file
	}

	checkAndSubmitModal = () => {
		const props = {
			onRemove: (file: any) => {
				this.setState({ file });
			},
			beforeUpload: (file: any) => {
				this.setState({ file });
				return false;
			},
			multiple: false,
		};

		const modalSublit = (
			<Modal
				title='Please Check And Submit'
				visible={this.state.visible}
				onOk={this.handleOk}
				okText='Submit'
				confirmLoading={this.state.confirmLoading}
				onCancel={this.handleCancel}>
				<div>
					{this.state.task_list.length > 0 &&
						this.state.task_list.map((value: any, index: any) => (
							<Col style={{ marginTop: 12 }}>
								<Row>
									<Col span={12}>
										<Text>{value.task_description}</Text>
									</Col>
									<Col span={12} onClick={this.showModal}>
										<Select
											style={{ width: 220 }}
											defaultValue={
												value["approveLevel"].filter(
													(x: any) => x.key === value.approvalStatus
												)[0].value
											}
											onChange={this.handleChange}
											placeholder='Select One'>
											{value["approveLevel"].length > 0 &&
												value["approveLevel"].map((taskList: TaskList) => (
													<Option key={taskList.id} value={value.id + "_" + taskList.key}>
														{taskList.value}
													</Option>
												))}
										</Select>
									</Col>
								</Row>
							</Col>
						))}

					<Form.Item style={{ marginTop: 20 }}>
						<TextArea
							rows={4}
							defaultValue={this.state.comment}
							onChange={this.commentChange}
							placeholder='Comment'
						/>
					</Form.Item>
					{this.state.show_image_uploder ? null : (
						<Form.Item>
							<div>
								<SingleFileUploader
									accept={defaultImageExtensions}
									onChange={(file: any) => this.setState({ file })}
								/>

								{/* <Upload.Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single file upload. Strictly prohibit from uploading company data or other
                                        band files
                                                    </p>
                                    {this.state.isApproveImageMandatory && <p style={{ color: "#ff0909" }}>* required</p>}
                                </Upload.Dragger> */}
							</div>
						</Form.Item>
					)}
				</div>
			</Modal>
		);

		return modalSublit;
	};

	emailReceiverChange = (e: any) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
			this.setState({ emailReceiver: e.target.value });
			this.setState({ receiverEmailError: false });
		} else {
			this.setState({ receiverEmailError: true });
		}
	};

	emailCcChange = (e: any) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
			this.setState({ emailCc: e.target.value });
			this.setState({ ccEmailError: false });
		} else {
			this.setState({ ccEmailError: true });
		}
	};

	emailBccChange = (e: any) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
			this.setState({ emailBcc: e.target.value });
			this.setState({ bccEmailError: false });
		} else {
			this.setState({ bccEmailError: true });
		}
	};

	emailSubjectChange = (e: any) => {
		this.setState({ emailSubject: e.target.value });
	};

	emailContentChange = (e: any) => {
		this.setState({ emailContent: e.target.value });
	};

	functionSendEmail = async () => {
		if (
			this.state.emailReceiver == "" ||
			this.state.emailSubject == "" ||
			this.state.emailContent == ""
		) {
			message.error("Please Fill All Required(*) Fields");
			return;
		}
		this.setState({ loading_email: true });
		var form = new FormData();

		console.log(lionUserManager.user.data.USER_ID);
		console.log(
			this.props.ArtworkRepo.selectedArtwork.minorVersions.filter(
				(minorVersions: any) => minorVersions.active == true
			)[0].id
		);
		console.log(this.state.emailReceiver);

		form.append("userID", lionUserManager.user.data.USER_ID);
		form.append(
			"minorVersionId",
			this.props.ArtworkRepo.selectedArtwork.minorVersions.filter(
				(minorVersions: any) => minorVersions.active == true
			)[0].id
		);
		form.append("sendTo", this.state.emailReceiver);
		form.append("bcc", this.state.emailBcc);
		form.append("cc", this.state.emailCc);
		form.append("subject", this.state.emailSubject);
		form.append("body", this.state.emailContent);

		var response = await this.props.sendEmail(form);
		if (response) {
			message.success("Done");
			this.setState({ loading_email: false, visible_email: false });
		} else {
			message.error("Error");
			this.setState({ loading_email: false, visible_email: false });
		}
	};

	renderArtworkStatusBadge = () => {
		const { selectedArtwork } = this.props.ArtworkRepo;

		if (selectedArtwork.isMainVersionLock) {
			return <Badge style={{ backgroundColor: "#52c41a" }} count='COMPLETED' />;
		}

		if (selectedArtwork.isReject && !selectedArtwork.isActive) {
			return (
				<Badge style={{ backgroundColor: "#f5222d" }} count='REJECTED COMPLETELY' />
			);
		}

		if (selectedArtwork.isReject) {
			return <Badge style={{ backgroundColor: "#f5222d" }} count='REJECTED' />;
		}

		return <Badge style={{ backgroundColor: "#ffc440" }} count='PENDING' />;
	};

	renderAccountWiseArtworkStatusBadge = () => {
		const { userViseArtworkStatus } = this.props.ArtworkRepo;
		return (
			<Badge count={userViseArtworkStatus} style={{ backgroundColor: "#aaa" }} />
		);
	};

	render() {
		const { userTaskList, selectedArtwork, artworkSummary } =
			this.props.ArtworkRepo;

		return (
			<div>
				<Spin spinning={this.state.loading} tip='Please Wait....'>
					<div style={{ opacity: this.state.loading ? 0.1 : 1 }}>
						{selectedArtwork && (
							<Row>
								<Col span={8}>
									<Col>
										<Row>
											{selectedArtwork.minorVersions.length > 0 && (
												<img
													onClick={() => this.showImageView(selectedArtwork.activeImage)}
													alt='example'
													style={{ maxHeight: 600, maxWidth: 360 }}
													src={imgBaseUrl + selectedArtwork.activeImage}
												/>
											)}
										</Row>
									</Col>
									<Col style={{ marginTop: 10 }} flex='auto'>
										{sub_image_count > 0 && <Text strong>Related Files</Text>}
										<Row style={{ marginTop: 10 }}>
											{selectedArtwork &&
												selectedArtwork.minorVersions.map(
													(minorVersion: MinorVersions) =>
														minorVersion.active == true &&
														minorVersion.imageses.map((image: any) => (
															<Tooltip title={image.comment}>
																<Col
																	style={{ margin: 5 }}
																	span={3}
																	onClick={() => this.showImageView(image.thumbnail)}>
																	<img
																		alt='example'
																		className='img-sub'
																		style={{}}
																		src={imgBaseUrl + image.thumbnail}
																	/>
																</Col>
																<Checkbox
																	value={image.thumbnail}
																	onChange={this.CheckedSubImages}>
																	Viewed
																</Checkbox>
															</Tooltip>
														))
												)}
										</Row>
									</Col>
								</Col>
								<Col span={16}>
									<Row>
										<Col span={18}>
											<Title level={4}>
												{selectedArtwork.code} /{" "}
												{selectedArtwork.create_on.split(".")[0].replace("T", " ")}
											</Title>
										</Col>
										{/* {selectedArtwork.isMainVersionLock ? (
											<Col span={4}>
												<Button
													style={{ backgroundColor: "#52c41a", borderColor: "#52c41a" }}
													type='primary'
													shape='round'>
													COMPLETED
												</Button>
											</Col>
										) : selectedArtwork.isReject === true ? (
											<Col span={4}>
												<Button
													style={{ backgroundColor: "#f5222d", borderColor: "#fff" }}
													type='primary'
													shape='round'>
													REJECTED
												</Button>
											</Col>
										) : (
											<Col span={4}>
												<Button
													style={{ backgroundColor: "#ffc440", borderColor: "#fff" }}
													type='primary'
													shape='round'>
													PENDING
												</Button>
											</Col>
										)} */}
									</Row>

									<Paragraph>
										<Row gutter={10} align='middle'>
											<Col>
												<Text strong>Artwork Status</Text>
											</Col>
											<Col>{this.renderArtworkStatusBadge()}</Col>
										</Row>
									</Paragraph>
									<Paragraph>
										<Row gutter={10} align='middle'>
											<Col>
												<Text strong>Your Status</Text>
											</Col>
											<Col>{this.renderAccountWiseArtworkStatusBadge()}</Col>
										</Row>
									</Paragraph>

									<Paragraph>
										<Text strong>Reason : </Text>{" "}
										{selectedArtwork.reason.trim() != "" ? (
											selectedArtwork.reason
										) : (
											<i>No Reason Is Provides By Creator</i>
										)}
									</Paragraph>
									<Paragraph>
										<Text strong>Description : </Text>{" "}
										{selectedArtwork.description.trim() != "" ? (
											selectedArtwork.description
										) : (
											<i>No Description Is Provides By Creator</i>
										)}
									</Paragraph>
									{selectedArtwork.minorVersions.filter(
										(minorVersions: any) => minorVersions.active == true
									)[0] &&
										selectedArtwork.minorVersions.filter(
											(minorVersions: any) => minorVersions.active == true
										)[0].reason != "" &&
										selectedArtwork.minorVersions.filter(
											(minorVersions: any) => minorVersions.active == true
										)[0].reason != null && (
											<Paragraph>
												<Text strong>Resubmit Reason : </Text>{" "}
												{selectedArtwork.description.trim() != "" ? (
													selectedArtwork.minorVersions.filter(
														(minorVersions: any) => minorVersions.active == true
													)[0].reason
												) : (
													<i>No Description Is Provides By Creator</i>
												)}
											</Paragraph>
										)}
									<Text strong>Other Options</Text>
									<div style={{ marginTop: 8, cursor: "hand", width: "98%" }}>
										{/* <Popover content={content} >
                                            <Button style={{ marginRight: 5 }} type="default">View Comments</Button>
                                        </Popover> */}

										{/* <Button type="default" onClick={() => this.copyurl(imgBaseUrl + selectedArtwork.activeImage)}>
                                    <SnippetsOutlined />   Copy Link
                                        </Button> */}
										{lionUserManager.user.data.AUTHORITIES.includes("shareArtwork") && (
											<Button
												onClick={() => this.sendEmail()}
												type='default'
												style={{ marginLeft: 5 }}>
												<MailOutlined /> Send E mail
											</Button>
										)}

										{/* <a href={imgBaseUrl + selectedArtwork.activeImage}  > */}
										<a href={`${orgImageBaseUrl}${selectedArtwork.activeImage}`}>
											<Button type='default' style={{ marginLeft: 5 }}>
												<DownloadOutlined /> Download
											</Button>
										</a>

										<ReactToPrint
											trigger={() => (
												<Button type='default' style={{ marginLeft: 5 }}>
													<PrinterOutlined /> Print
												</Button>
											)}
											content={() => this.componentRef}></ReactToPrint>

										{this.state.enableDoc && (
											<Button
												onClick={this.saveDocx}
												type='default'
												style={{ marginLeft: 5 }}>
												<DownloadOutlined /> Doc Download
											</Button>
										)}

										<div style={{ display: "none" }}>
											<PrintComponent
												img={imgBaseUrl + selectedArtwork.activeImage}
												code={selectedArtwork.code}
												name={"sasa"}
												subImages={sub_images}
												ref={(el) => (this.componentRef = el)}
											/>{" "}
										</div>
									</div>
									{
										selectedArtwork && selectedArtwork.user != null && (
											<Row>
												<Text strong style={{ textAlign: "center", marginTop: 20 }}>
													Created By -
												</Text>
												<PageHeader
													style={{ width: "60%" }}
													title=''
													subTitle={
														selectedArtwork.user.firstName +
														" " +
														selectedArtwork.user.lastName
													}
													avatar={{
														src: selectedArtwork.user.userDetails.imagePaths,
													}}></PageHeader>
											</Row>
										)

										// <Row style={{marginTop : 20}}>
										//     <img alt="example" style={{ width: "10%" , borderRadius: 8 }} src={ } />
										//     <Text style={{marginLeft : 10}} strong>Created By : </Text>
										//     <Text  >{}</Text>
										// </Row>
									}
									<div style={{ marginTop: 16 }}>
										<Row>
											<div>
												{selectedArtwork.isReject ||
													(userTaskList &&
														userTaskList.ckeckList.length > 0 &&
														!selectedArtwork.isMainVersionLock &&
														(sub_image_count == 0 || this.state.checkedSubImages) && (
															<Col span={8} onClick={this.showModal}>
																<Button type='danger' ghost shape='round'>
																	CHECK & SUBMIT
																</Button>
															</Col>
														))}
												{selectedArtwork.isActive &&
													selectedArtwork.isReject &&
													lionUserManager.user.data.AUTHORITIES.includes("initiator") && (
														<Col span={7} onClick={this.showModalResubmit}>
															<Button type='primary' ghost shape='round'>
																RESUBMIT
															</Button>
														</Col>
													)}
											</div>
										</Row>
										{this.resubmitModal()}
										{this.checkAndSubmitModal()}
									</div>
									<Divider />
									{lionUserManager.user.data.AUTHORITIES.includes("initiator") && (
										<div>
											<Text strong>View Detail Of Minor Version</Text>

											<div style={{ marginTop: 15, cursor: "hand", width: "98%" }}>
												<Button
													style={{ marginLeft: 5 }}
													onClick={() => this.showComments()}
													type='default'>
													<FormOutlined /> Comments
												</Button>

												<Button
													style={{ marginLeft: 5 }}
													onClick={() => this.showModalSummaryDetails()}
													type='primary'>
													<FormOutlined /> View Summary
												</Button>
											</div>
										</div>
									)}
								</Col>

								<Drawer
									width={640}
									placement='right'
									closable={false}
									onClose={this.onClose}
									visible={this.state.visible1}>
									<Col>
										{this.state.minorVersions.approveLevelRoleInvolvements &&
											this.state.minorVersions.approveLevelRoleInvolvements.length > 0 &&
											this.state.minorVersions.approveLevelRoleInvolvements.map(
												(approveLevelRoleInvolvements: ApproveLevelRoleInvolvements) =>
													approveLevelRoleInvolvements.rejectedTasks.length > 0 && (
														<div style={{}}>
															<PageHeader
																style={{ width: "100%", padding: 2 }}
																title=''
																tags={
																	<Tag>
																		{approveLevelRoleInvolvements.user.userDetails.firstName +
																			" " +
																			approveLevelRoleInvolvements.user.userDetails.lastName +
																			" / " +
																			approveLevelRoleInvolvements.user.userDetails
																				.designationName}
																	</Tag>
																}
																avatar={{
																	src: approveLevelRoleInvolvements.user.userDetails.imagePaths,
																}}></PageHeader>
															<Row style={{ marginTop: 20 }}>
																<Col span={12}>
																	<p>
																		<PictureOutlined /> Suggested Image
																	</p>
																	{approveLevelRoleInvolvements.imagePath && (
																		<img
																			alt='example'
																			style={{ width: "94%" }}
																			src={imgBaseUrl + approveLevelRoleInvolvements.imagePath}
																		/>
																	)}
																</Col>
																<Col span={12}>
																	<p style={{ color: "#f5222d" }}>
																		<MinusCircleOutlined /> Rejected Task
																	</p>
																	{approveLevelRoleInvolvements.rejectedTasks &&
																		approveLevelRoleInvolvements.rejectedTasks.map(
																			(rejectedTasks: RejectedTasks) => (
																				<p key={rejectedTasks.id} style={{ marginLeft: 20 }}>
																					* {rejectedTasks.description}
																				</p>
																			)
																		)}
																</Col>
															</Row>
														</div>
													)
											)}
									</Col>
									{/* */}
								</Drawer>

								<Drawer
									width={700}
									placement='right'
									closable={false}
									onClose={this.onClose}
									visible={this.state.visible_summary_modal}>
									{artworkSummary && artworkSummary.length > 0 ? (
										artworkSummary.map((version_summary: VersionSummary) => (
											<div>
												<h4 style={{ marginTop: 5 }}>
													{" "}
													{version_summary.approveLevelDescription}{" "}
												</h4>
												{version_summary.users.map((users: any) => (
													<Row className='row-layout-style' style={{ marginTop: 30 }}>
														<Col
															className='row-layout-items'
															span={8}
															style={{
																backgroundColor: "#fafafa",
																padding: 6,
																height: "100%",
															}}>
															<div style={{ width: "100%", marginTop: 10 }}>
																<PageHeader
																	title=''
																	className='site-page-header'
																	subTitle={users.firstName}
																	avatar={{ src: users.imagePaths }}></PageHeader>
																<p style={{ marginLeft: 10, fontSize: 12, color: "#aba8a8" }}>
																	{users.designationName}
																</p>
															</div>
														</Col>
														<Col span={16}>
															{users.tasks.map((tasks: any) => (
																<div>
																	<Row style={{ margin: 5 }}>
																		<Col span={18} style={{ paddingRight: 5 }}>
																			<p>
																				<InboxOutlined style={{ color: "#08c", marginRight: 5 }} />
																				{tasks.taskDescription}
																			</p>{" "}
																		</Col>

																		<Col span={4}>
																			{tasks.taskApprovalStatus == -1 && (
																				<Tag color='#ffaa00bf'>PENDING</Tag>
																			)}

																			{tasks.taskApprovalStatus == 1 && (
																				<Tag color='#13c518'>APPROVED</Tag>
																			)}

																			{tasks.taskApprovalStatus == 0 && (
																				<Tag color='#f5222d'>REJECTED</Tag>
																			)}
																		</Col>
																	</Row>

																	{tasks.update_on && (
																		<p style={{ marginLeft: 10, fontSize: 10, color: "#aba8a8" }}>
																			{tasks.update_on.replace("T", " ").split(".")[0]}
																		</p>
																	)}
																</div>
															))}
														</Col>
													</Row>
												))}
											</div>
										))
									) : (
										<div style={{ height: "100%", width: "100%", marginTop: 120 }}>
											<Empty />
										</div>
									)}
								</Drawer>

								<Drawer
									width={470}
									placement='right'
									closable={false}
									onClose={this.onClose}
									visible={this.state.visible_email}
									destroyOnClose={true}>
									<Spin spinning={this.state.loading_email} tip='Please Wait....'>
										<Text strong>Send Email</Text>

										<Form.Item style={{ marginTop: 20 }}>
											<Input
												onChange={this.emailReceiverChange}
												placeholder='To *'
												style={{ marginBottom: 10 }}
											/>
											{this.state.receiverEmailError && (
												<p style={{ color: "#ef0909" }}>Invalid Email</p>
											)}
											<Input
												onChange={this.emailCcChange}
												placeholder='CC'
												style={{ marginBottom: 10 }}
											/>
											{this.state.ccEmailError && (
												<p style={{ color: "#ef0909" }}>Invalid Email</p>
											)}
											<Input
												onChange={this.emailBccChange}
												placeholder='BCC'
												style={{ marginBottom: 10 }}
											/>
											{this.state.bccEmailError && (
												<p style={{ color: "#ef0909" }}>Invalid Email</p>
											)}
											<TextArea
												onChange={this.emailSubjectChange}
												rows={1}
												placeholder='Subject *'
											/>
											<TextArea
												onChange={this.emailContentChange}
												rows={6}
												placeholder='Content *'
											/>
										</Form.Item>
										<Form.Item>
											<Row>
												{" "}
												<Text strong>Main Artwork</Text>
											</Row>
											{(selectedArtwork.minorVersions &&
												selectedArtwork.minorVersions.length) > 0 ? (
												<img
													onClick={() => this.showImageView(selectedArtwork.activeImage)}
													alt='example'
													style={{ maxHeight: 400, maxWidth: 180 }}
													src={imgBaseUrl + selectedArtwork.activeImage}
												/>
											) : (
												<></>
											)}
											<Col style={{ marginTop: 10 }} flex='auto'>
												{sub_image_count > 0 && <Text strong>Related Files</Text>}
												<Row style={{ marginTop: 10 }} gutter={[16, 24]}>
													{selectedArtwork &&
														selectedArtwork.minorVersions.map(
															(minorVersion: MinorVersions) =>
																minorVersion.active == true &&
																minorVersion.imageses.map((image: any) => (
																	<Tooltip title={image.comment}>
																		<Col style={{ margin: 5 }} span={7}>
																			<Row onClick={() => this.showImageView(image.thumbnail)}>
																				<Col span={241}>
																					<img
																						alt='example'
																						style={{ width: "80%", height: 60 }}
																						src={imgBaseUrl + image.thumbnail}
																					/>
																				</Col>
																			</Row>
																		</Col>
																	</Tooltip>
																))
														)}
												</Row>
											</Col>
											{/* {selectedArtwork.minorVersions.length > 0 && <img onClick={() => this.showImageView(selectedArtwork.activeImage)} alt="example" style={{ maxHeight: 400 , maxWidth : 180 }} src={imgBaseUrl + selectedArtwork.activeImage} />}
                                    <Row><Text strong>Related Files  </Text></Row>  
                                    {selectedArtwork && selectedArtwork.minorVersions.map((minorVersion: MinorVersions) =>
                                            minorVersion.active == true && minorVersion.imageses.map((image: any) =>
                                                <Tooltip title="Click Here To Copy File Link Address"  >
                                                    <Col style={{ marginTop: 5 }} span={7}> 
                                                            <Col span={24}>
                                                                <img alt="example" style={{ width: "100%"  }} src={imgBaseUrl + image.thumbnail} />

                                                            </Col>  
                                                    </Col>
                                                </Tooltip>
                                            )
                                            )}   */}

											<Button
												htmlType='submit'
												onClick={this.functionSendEmail}
												type='primary'>
												Send Email
											</Button>
										</Form.Item>
									</Spin>
								</Drawer>

								<Drawer
									width={670}
									placement='right'
									closable={false}
									onClose={this.onClose}
									visible={this.state.visible_comments}>
									<Text strong>Comments</Text>
									{selectedArtwork &&
										selectedArtwork.minorVersions.map((minorVersions: MinorVersions) =>
											minorVersions.active == true ? (
												minorVersions.approveLevelRoleInvolvements &&
												minorVersions.approveLevelRoleInvolvements.map(
													(approveLevelRoleInvolvements: ApproveLevelRoleInvolvements) => (
														<div key={approveLevelRoleInvolvements.id}>
															<PageHeader
																style={{ width: "100%", padding: 6, marginTop: 10 }}
																title=''
																tags={
																	<Text strong>
																		{approveLevelRoleInvolvements.user.userDetails.firstName +
																			" " +
																			approveLevelRoleInvolvements.user.userDetails.lastName +
																			" / " +
																			approveLevelRoleInvolvements.user.userDetails
																				.designationName}
																	</Text>
																}
																subTitle=''
																avatar={{
																	src: approveLevelRoleInvolvements.user.userDetails.imagePaths,
																}}></PageHeader>
															<p style={{ marginLeft: 50, fontSize: 10 }}>
																Created On -{" "}
																{
																	approveLevelRoleInvolvements.create_on
																		.replace("T", " ")
																		.split(".")[0]
																}
															</p>
															{approveLevelRoleInvolvements.comment != "" ? (
																<p style={{ marginLeft: 50 }}>
																	{" "}
																	* {approveLevelRoleInvolvements.comment}
																</p>
															) : (
																<p style={{ marginLeft: 50 }}> * No Comments</p>
															)}
															{/* {approveLevelRoleInvolvements.imagePath  && <img alt="example" style={{ height: "10%", width: "10%" }} src={  !(approveLevelRoleInvolvements.imagePath.includes(".docx")) ?  imgBaseUrl + approveLevelRoleInvolvements.imagePath : "https://lionapi.eimsky.com/artwork/downloadFile/Lion-Stout v1.1.jpg"} />} */}
															{approveLevelRoleInvolvements.imagePath && (
																<img
																	alt='example'
																	style={{ height: "10%", width: "10%" }}
																	src={
																		!(
																			approveLevelRoleInvolvements.imagePath.includes(".docx") ||
																			approveLevelRoleInvolvements.imagePath.includes(".pdf")
																		)
																			? imgBaseUrl + approveLevelRoleInvolvements.imagePath
																			: logo_word
																	}
																/>
															)}
															{approveLevelRoleInvolvements.imagePath && (
																<a href={imgBaseUrl + approveLevelRoleInvolvements.imagePath}>
																	<Button type='default' style={{ marginLeft: 5 }}>
																		<DownloadOutlined /> Download
																	</Button>
																</a>
															)}
														</div>
													)
												)
											) : (
												// <div><Empty /></div>
												<div> </div>
											)
										)}
								</Drawer>
							</Row>
						)}

						{selectedArtwork && (
							<div style={{ marginTop: 40 }}>
								{selectedArtwork.minorVersions &&
									selectedArtwork.minorVersions.map((minorVersions: MinorVersions) => (
										<div key={minorVersions.id}>
											{minorVersions.active == true ? null : (
												<Col span={24}>
													<Card style={{ marginBottom: 20 }}>
														<Row style={{ outline: 1, borderColor: "#000" }}>
															<Col span={1}>
																<FileOutlined />
															</Col>
															<Col span={22}>
																<Row>
																	<Col span={21}>
																		<Row>
																			<Col span={8}>
																				<Title level={4}> Version {minorVersions.versionNo} </Title>
																			</Col>
																		</Row>
																	</Col>
																	<Col span={3}>
																		<Col span={8}>
																			<Tag color='#f5222d'>REJECTED</Tag>
																		</Col>
																	</Col>
																</Row>
																<Row>
																	<Col flex='auto'>
																		<img
																			alt='example'
																			style={{ width: "auto", height: 200 }}
																			src={imgBaseUrl + minorVersions.original_image}
																		/>
																	</Col>
																	<Col span={10}>
																		<p style={{ color: "#52c41a" }}>
																			<MinusCircleOutlined /> ALL Comments
																		</p>

																		{minorVersions.approveLevelRoleInvolvements &&
																			minorVersions.approveLevelRoleInvolvements.map(
																				(
																					approveLevelRoleInvolvements: ApproveLevelRoleInvolvements
																				) => (
																					<div key={approveLevelRoleInvolvements.id}>
																						<PageHeader
																							style={{ width: "100%", padding: 6 }}
																							title=''
																							tags={
																								<Tag>
																									{approveLevelRoleInvolvements.user.userDetails
																										.firstName +
																										" " +
																										approveLevelRoleInvolvements.user.userDetails
																											.lastName +
																										" / " +
																										approveLevelRoleInvolvements.user.userDetails
																											.designationName}
																								</Tag>
																							}
																							subTitle=''
																							avatar={{
																								src: approveLevelRoleInvolvements.user.userDetails
																									.imagePaths,
																							}}></PageHeader>
																						{approveLevelRoleInvolvements.comment != "" ? (
																							<p style={{ marginLeft: 50, color: "#d9363e" }}>
																								{" "}
																								* {approveLevelRoleInvolvements.comment}
																							</p>
																						) : (
																							<p style={{ marginLeft: 50 }}> * No Comment</p>
																						)}
																						{approveLevelRoleInvolvements.create_on && (
																							<p style={{ marginLeft: 50, fontSize: 10 }}>
																								{" "}
																								{
																									approveLevelRoleInvolvements.create_on
																										.replace("T", " ")
																										.split(".")[0]
																								}
																							</p>
																						)}
																					</div>
																				)
																			)}

																		<Row>
																			<Col span={11}>
																				<Button
																					style={{ marginTop: 10 }}
																					type='default'
																					onClick={() => this.viewMinerVersion(minorVersions)}>
																					VIEW REJECTED TASKS{" "}
																				</Button>
																			</Col>
																			<Col span={11}>
																				<Button
																					style={{ marginTop: 10 }}
																					type='default'
																					onClick={() => this.viewSummary(minorVersions)}>
																					VIEW SUMMARY{" "}
																				</Button>
																			</Col>
																		</Row>
																	</Col>

																	<Col span={8}>
																		<Text strong>Related Files</Text>
																		{minorVersions.imageses.map((image: any) => (
																			<div style={{ margin: 5 }}>
																				<Row>
																					<Col span={5}>
																						<img
																							alt='example'
																							style={{ width: "80%" }}
																							src={imgBaseUrl + image.thumbnail}
																						/>
																					</Col>

																					<Col span={8}>
																						<Text> {image.comment}</Text>
																					</Col>
																					<Col span={7}>
																						<Button
																							type='default'
																							onClick={() => this.copyurl(image.imageUrl)}>
																							<SnippetsOutlined />
																						</Button>
																					</Col>
																				</Row>
																			</div>
																		))}
																	</Col>
																</Row>
															</Col>
														</Row>
													</Card>
												</Col>
											)}
										</div>
									))}
							</div>
						)}
					</div>
				</Spin>
			</div>
		);
	}
}

// const WrappedArtWorkView = Form.create({ name: 'viewArtWork' })(ViewArtWork);
const WrappedArtWorkView = ViewArtWork;
const withArtworksRepo = connect(
	(state: any) => state,
	ArtWorkActions
)(WrappedArtWorkView);
export default withRouter(withArtworksRepo as FunctionComponent);
