import { INode, INodeCollection } from './module';

export class Node implements INode<any> {
    id: number;
    PiD: number;
    name: string;
    type: string;
    data: any;
    isLeaf?: boolean;
    constructor(props: INode<any>) {
        const { id,
            isLeaf,
            PiD,
            name,
            type,
            data } = props;
        this.id = id;
        this.isLeaf = isLeaf
        this.PiD = PiD;
        this.name = name;
        this.type = type;
        this.data = data;
    };
}

export const createRepo = (data: any, i: number) => {
    var repo: Array<INode<any>> = [];
    var index = 0
    const traverse = (props: Node) => {
        index++
        let { data, id } = props;
        repo.push({ ...props, isLeaf: data.sub.length === 0 })

        //look into sub
        if (data.sub && data.sub.length > 0) {
            data.sub.forEach((e: any, _i: number) => {
                traverse(new Node({ id: index, PiD: id, name: e.masterData.name, type: e.type.name, data: e }));
            })
        }
    }
    traverse(new Node({ id: index, PiD: -1, name: data.masterData.name, type: data.type.name, data: data }));
    return repo;
}

export const getHistory = (node: INode<any>, repo: Array<INode<any>>) => {
    let history: Array<INode<any>> = [node];

    let currentNode = node;

    while(currentNode !== undefined && currentNode.id !== -1){
        const _node = getParent(currentNode, repo);
        if(_node){
            currentNode = _node;
            history.push(currentNode);
        }else{
            break;
        }
    }

    return history
}

export const getParent = (node: INode<any>, repo: Array<INode<any>>): INode<any> | undefined => repo.find((e) => e.id === node.PiD)

export const getChildren = (node: INode<any>, repo: Array<INode<any>>): Array<INode<any>> => repo.filter((e) => e.PiD === node.id);
