import {normalize, normalizeMainCat} from './createRepo';
import CategoryFilter from './DefaultColumnGallery';
// import CategoryFilter from './CategoryFilter';
// import './style.scss'
export {
    CategoryFilter,
    normalize,
    normalizeMainCat
}

export * from './ColumnGallery'

export const mock = [
    {
        "main_cat": [
            {
                "id": 1,
                "masterData": {
                    "id": 1,
                    "name": "Lion"
                },
                "type": {
                    "id": 1,
                    "name": "company"
                },
                "sub": [
                    {
                        "id": 6,
                        "masterData": {
                            "id": 6,
                            "name": "Carlsberg"
                        },
                        "type": {
                            "id": 2,
                            "name": "brand"
                        },
                        "sub": []
                    },
                    {
                        "id": 3,
                        "masterData": {
                            "id": 3,
                            "name": "Stout"
                        },
                        "type": {
                            "id": 2,
                            "name": "brand"
                        },
                        "sub": []
                    },
                    {
                        "id": 4,
                        "masterData": {
                            "id": 4,
                            "name": "Lager"
                        },
                        "type": {
                            "id": 2,
                            "name": "brand"
                        },
                        "sub": [
                            {
                                "id": 10,
                                "masterData": {
                                    "id": 10,
                                    "name": "Export"
                                },
                                "type": {
                                    "id": 3,
                                    "name": "trade"
                                },
                                "sub": []
                            },
                            {
                                "id": 9,
                                "masterData": {
                                    "id": 9,
                                    "name": "Local"
                                },
                                "type": {
                                    "id": 3,
                                    "name": "trade"
                                },
                                "sub": []
                            }
                        ]
                    },
                    {
                        "id": 5,
                        "masterData": {
                            "id": 5,
                            "name": "Strong"
                        },
                        "type": {
                            "id": 2,
                            "name": "brand"
                        },
                        "sub": []
                    }
                ]
            },
            {
                "id": 2,
                "masterData": {
                    "id": 2,
                    "name": "Luxury brands"
                },
                "type": {
                    "id": 1,
                    "name": "company"
                },
                "sub": [
                    {
                        "id": 8,
                        "masterData": {
                            "id": 8,
                            "name": "Black_Lable"
                        },
                        "type": {
                            "id": 2,
                            "name": "brand"
                        },
                        "sub": []
                    },
                    {
                        "id": 7,
                        "masterData": {
                            "id": 7,
                            "name": "Corona"
                        },
                        "type": {
                            "id": 2,
                            "name": "brand"
                        },
                        "sub": []
                    }
                ]
            }
        ]
    },
    {
        "main_cat": [
            {
                "id": 13,
                "masterData": {
                    "id": 13,
                    "name": "keg"
                },
                "type": {
                    "id": 4,
                    "name": "type"
                },
                "sub": []
            },
            {
                "id": 12,
                "masterData": {
                    "id": 12,
                    "name": "cann"
                },
                "type": {
                    "id": 4,
                    "name": "type"
                },
                "sub": [
                    {
                        "id": 18,
                        "masterData": {
                            "id": 15,
                            "name": "Body"
                        },
                        "type": {
                            "id": 5,
                            "name": "possition"
                        },
                        "sub": []
                    }
                ]
            },
            {
                "id": 11,
                "masterData": {
                    "id": 11,
                    "name": "Bottle"
                },
                "type": {
                    "id": 4,
                    "name": "type"
                },
                "sub": [
                    {
                        "id": 15,
                        "masterData": {
                            "id": 15,
                            "name": "Body"
                        },
                        "type": {
                            "id": 5,
                            "name": "possition"
                        },
                        "sub": [
                            {"id": 17,
                            "masterData": {
                                "id": 17,
                                "name": "660ML"
                            },
                            "type": {
                                "id": 6,
                                "name": "sku"
                            },
                            "sub": []}
                        ]
                    },
                    {
                        "id": 17,
                        "masterData": {
                            "id": 17,
                            "name": "660ML"
                        },
                        "type": {
                            "id": 6,
                            "name": "sku"
                        },
                        "sub": []
                    },
                    {
                        "id": 16,
                        "masterData": {
                            "id": 16,
                            "name": "330ML"
                        },
                        "type": {
                            "id": 6,
                            "name": "sku"
                        },
                        "sub": []
                    },
                    {
                        "id": 14,
                        "masterData": {
                            "id": 14,
                            "name": "neck"
                        },
                        "type": {
                            "id": 5,
                            "name": "possition"
                        },
                        "sub": []
                    }
                ]
            }
        ]
    }
]